import { useEffect, useState } from "react";
import { DatePicker } from "react-advance-jalaali-datepicker";
import NumberFormat from 'react-number-format';

const Field = (props) => {
    const [field, setField] = useState('');
    const [value, setValue] = useState(props.value);
    const handleChange = (e) => {
        if (props.type === 'file') {
            setValue(e.target.files[0]);
        } else {
            setValue(e.target.value);
        }
    }


    useEffect(() => {
        props.change(value);
    }, [value])



    return (
        <>
            {
                props.type === 'text' ?
                    <input
                        onChange={handleChange}
                        value={value}
                        type='text'
                        className={`${props.className} effect-7`}
                        placeholder={props.placeholder ? props.placeholder : null}
                        id={props.id ? props.id : null}
                        name={props.name ? props.name : null}
                    />
                    :
                    null
            }
            {
                props.type === 'number' ?
                    <NumberFormat
                        dir={props.dir ? props.dir : 'rtl'}
                        value={value}
                        onValueChange={(val) => { setValue(val.value) }}
                        className={`${props.className} effect-7`}
                        displayType='number'
                        thousandSeparator={props.seprator ? props.seprator : false}
                        prefix={props.perfix ? props.perfix : null}
                        format={props.format ? props.format : null}
                        placeholder={props.placeholder ? props.placeholder : null}
                        renderText={(value, props) => <div {...props}>{value}</div>} />
                    :
                    null
            }
            {
                props.type === 'date' ?
                    <div className="position-relative forms-date w-100">
                        <DatePicker
                            placeholder={props.placeholder ? props.placeholder : null}
                            format="jYYYY/jMM/jDD"
                            onChange={(timeStamp, persianDate) => { setValue(persianDate) }}
                            id={props.id ? props.id : null}
                            preSelected={value}

                            inputComponent={(props) => { return <input className={`${props.className} effect-7 pr-5 w-100`} {...props} /> }}
                        />
                        <i className="fa fa-calendar beforeElement" ></i>
                    </div>
                    :
                    null
            }
            {
                props.type === 'file' ?
                    <div className="w-100 d-flex flex-row-reverse justify-content-between ">
                        <span>
                            {
                                value ?
                                   <>
                                      {value.name ? value.name.substr(-25) : ''}
                                   </>
                                 :
                                 null
                            }
                        </span>
                        <button onClick={(e) => { e.preventDefault(); document.getElementById('chooseFileBtn').click() }} className="btn btn-info m-0" >انتخاب فایل</button>
                        <input
                            onChange={handleChange}
                            type='file'
                            className='d-none'
                            placeholder={props.placeholder ? props.placeholder : 'انتخاب فایل'}
                            id='chooseFileBtn'
                            name={props.name ? props.name : null}
                        />
                    </div>
                    :
                    null
            }

        </>
    )
}
export default Field;

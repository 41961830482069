import React, {Component, useState, useEffect, memo} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import useScript from "../../hook/useScript";
import {useCookies} from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import Loader from '../../components/loader';
import Notification from "../../components/notification";

// لیست خودرو ها
const ModuleCarMain = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    //بررسی اجازه نمایش صفحه
    if (props.siteData.moduleCarAllowShow != 1)
        history.push("/");

    {document.title = 'ماژول خودرو'}


    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title='ماژول خودرو' back='true' help="helpModuleCar"/>
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1 moduleCarItems">
                                            <div className="col-12 item">
                                                <div className="card">
                                                    <Link to='/moduleCar/sales' className="text-center">
                                                        <h5>خرید خودرو</h5>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-12 item">
                                                <div className="card">
                                                    <Link to='/moduleCar/auctions' className="text-center">
                                                        <h5>مزایدات</h5>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-12 item">
                                                <div className="card">
                                                    <Link to='/moduleCar/rents' className="text-center">
                                                        <h5> اجاره خودرو </h5>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModuleCarMain;
import React, { Component, useState, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';

const TaskBar = () => {
    return (
        <div className="row userTaskBar">
            <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="quick-access clearfix">
                    <div>
                        <ul>
                            <li className="item item2">
                                <Link to="/my/scoreHistory" title="" data-link='http://club.azaran-group.com/my/scoreHistory'>
                                    <i className="icon icon3"></i>
                                </Link>
                            </li>
                            <li className="item item2">
                                <Link to="/comments" title="" data-link='http://club.azaran-group.com/comments'>
                                    <i className="icon icon2"></i>
                                </Link>
                            </li>
                            <li className="item item2">
                                <Link to="/" title="" className="active" data-link='http://club.azaran-group.com/'>
                                    <i className="icon icon1"></i>
                                </Link>
                            </li>
                            <li className="item item2">
                                <Link to="/aboutUs" title="" data-link='http://club.azaran-group.com/aboutUs'>
                                    <i className="icon icon4"></i>
                                </Link>
                            </li>
                            <li className="item item2">
                                <Link to="/my/profile" title="" data-link='http://club.azaran-group.com/my/profile'>
                                    <i className="icon icon5"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


    );
}
export default TaskBar;




import React, { Component, useState, useEffect, memo } from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import useScript from "../../hook/useScript";
import { useCookies } from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import Notification from "../../components/notification";
import Loader from "../../components/loader";

const Event = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    //بررسی اجازه نمایش صفحه
    if (props.siteData.eventAllowShow != 1)
        history.push("/");
    
    { document.title =  'رویدادهای من'}
    const [cookies, setCookie] = useCookies();
    const [event, setEvent] = useState([])

    // ###############################
    //  درخواست همه رویدادهای من
    useEffect(() => {
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/event/my';
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setEvent(response.data.data);
                }
                else {
                    Notification('error', response.data.data);
                }
                Loader('remove');
            })
    }, []);


    return (
        <>
            {/* کدهای اچ تی ام ال */}
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">

                            <Header siteData={props.siteData} title='رویدادهای من' back='true' />

                            <div className="visible-yscroll">

                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            {
                                                event.map((item, index) => {
                                                    return (
                                                        <div className="item col-12">
                                                            <div className="card">
                                                                <img src={item.photo} alt={item.title} title={item.title} />
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{item.title}</h5>

                                                                    <ul>
                                                                        <li>
                                                                        <b>ثبت نام برای : </b>
                                                                        {item.forMe == 1 && (<>
                                                                            خودم
                                                                        </>)}
                                                                        </li>
                                                                        {item.forMe == 0 && (<>
                                                                            <li><b>نام : </b> <span>{item.name}</span></li>
                                                                            <li><b>فامیل : </b> <span>{item.family}</span></li>
                                                                            <li><b>میزان تحصیلات : </b> <span>{item.education}</span></li>
                                                                            <li><b>رشته تحصیلی : </b> <span>{item.study}</span></li>
                                                                            <li><b>تلفن : </b> <span>{item.phone}</span></li>
                                                                            <li><b>آدرس : </b> <span>{item.address}</span></li>
                                                                        </>)}
                                                                    </ul>

                                                                    <p className="m-0"><b>تاریخ شروع : </b>{item.start}</p>
                                                                    <p><b>تاریخ پایان : </b>{item.end}</p>
                                                                    <Link to={'/event/detail/' + item.slug} className="btn btn-1 hover2">مشاهده رویداد</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Event;
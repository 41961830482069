import React, { Component, useState, useEffect, memo } from 'react';
import axios from 'axios';
import $ from 'jquery';
import useScript from './../hook/useScript';
import Notification from './../components/notification';
import { useCookies } from "react-cookie";
import Header from '../layouts/header';
import TaskBar from '../layouts/taskBar';
import Loader from '../components/loader';

// صفحه پشتیبانی
const Comments = (props) => {
    { document.title = 'پشتیبان' }
    const [cookies, setCookie] = useCookies();

    // ###############################
    // ###########       Set Data
    const [allComments, setAllComments] = useState([]);
    const [comment, setComment] = useState('');
    const [photo, setPhoto] = useState(null);
    const onChangePicture = e => {
        if (e.target.files[0]) {
            setPhoto(e.target.files[0]);
        }
    };

    // ###############################
    //  لیست تمام پیام ها
    useEffect(() => {
        const urlDisplay = process.env.REACT_APP_URL + '/api/v1/comment/all';
        Loader('show');
        axios({
            method: 'post',
            url: urlDisplay,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                    await setAllComments(response.data.data);
                }
                if (response.data.status === -1) {
                    // نمایش پیغام سرور
                    Notification('error', response.data.data);
                }
                Loader('remove');
            })
    }, []);


    // ###############################
    // ذخیره پیام
    const registerComment = async () => {
        let data = new FormData();
        data.append("uploadPhoto", photo);
        data.append('comment', comment);
        if (comment === '') {
            Notification('error', "وارد کردن نظر الزامی می باشد");
        } else {
            const url = process.env.REACT_APP_URL + '/api/v1/comment/store';
            Loader('show');
            axios({
                method: 'post', url: url,
                data,
                headers: {
                    Authorization: 'Bearer ' + cookies.tokenForAccess,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res => {
                    // بررسی وضعیت برگشت داده
                    // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                    if (res.data.status == 1) {
                        document.getElementById('commentElement').value = '';
                        setComment('');
                        setPhoto('');

                        let newComment = $('li.chatItemExample').first();
                        newComment = newComment.clone();
                        newComment.removeClass('chatItemExample disnone');
                        newComment.find('.comment span').text(res.data.data[0].date);
                        newComment.find('.comment p').text(res.data.data[0].comment);
                        $("#chatBox ul").append(newComment);
                        Notification('success', 'نظر با موفقیت ثبت گردید');
                        Loader('remove');
                    }
                    else {
                        // نمایش پیغام سرور
                        Notification('error', res.data.data);
                    }

                }))
                .catch(error => {
                    // بروز هرگونه خطا از سمت سرور
                    if (error.response) {
                        Notification('error', error.response);
                    }
                    Loader('remove');
                })
        }
    }

    return (
        <>
            {/* کد های اچ تی ام ال */}
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">

                            <Header siteData={props.siteData} title="پشتیبان" back='true' help="helpComments" />

                            <div className="visible-yscroll p-0">
                                <div className="row m-0">
                                    <div className="col-12 col-sm-12 col-md-12 m-0 p-0">
                                        <div id="chatBox">
                                            <ul>
                                                {
                                                    allComments.map((item, index) => {
                                                        return (

                                                            <li key={item.id}>
                                                                <div className={item.user_type == 1 ? 'left' : 'right'}>
                                                                    <div className="user">
                                                                        <i className={item.user_type == 0 ? 'fa fa-user-circle' : 'fas fa-headset'}></i>
                                                                        <p>{item.firstName} {item.lastName}</p>
                                                                    </div>

                                                                    <div className='comment'>
                                                                        <p>{item.comment}</p>
                                                                        <span className={item.user_type == 0 ? 'ml-auto' : 'mr-auto'}>{item.date}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className="row newCmmentSection">
                                <input type="text" className="form-control" id="commentElement" onChange={(value) => {
                                    setComment(value.currentTarget.value)
                                }} placeholder="متن خود را وارد کنید ... " />

                                <button className='btn btn-info p-0' onClick={registerComment}><i className="fas fa-location-arrow"></i></button>

                            </div>

                        </div>

                        <TaskBar />

                    </div>
                </div>

            </div>


            <li className="chatItemExample disnone">
                <div className='right'>
                    <div className="user">
                        <i className='fa fa-user-circle'></i>
                        <p></p>
                    </div>

                    <div className='comment'>
                        <p></p>
                        <span className='ml-auto'></span>
                    </div>
                </div>
            </li>

        </>
    )
}
export default Comments;
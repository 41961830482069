import React, { Component, useState, useEffect, memo } from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import useScript from "../../hook/useScript";
import { useCookies } from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import Notification from "../../components/notification";
import Loader from "../../components/loader";


// اخبار
const News = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    //بررسی اجازه نمایش صفحه
    if (props.siteData.newsAllowShow != 1)
        history.push("/");
    
    { document.title =  'اخبار'}
    const [cookies, setCookie] = useCookies();
    const [news, setNews] = useState([])

    // ###############################
    //  درخواست همه اخبار
    useEffect(() => {
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/news/all';
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setNews(response.data.data);
                }
                else {
                    Notification('error', response.data.data);
                }
                Loader('remove');
            })
    }, []);


    // ###############################
    // جستجو
    const [search, setSearch] = useState('');
    const searchForm = async () => {
        const url = process.env.REACT_APP_URL + '/api/v1/news/all';

        let data = new FormData();
        data.append("search", search);

        axios({
            method: 'post',
            data,
            url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    await setNews(response.data.data);
                } else {
                    Notification('error', response.data.data);
                }
            }).catch(async (error) => {
                // بورز خطا از سرور
            if (error.response) {
                if (error.response.status == 401) {
                    window.location.href = '/'
                }
            }

        })
    }
    return (
        <>
            {/* کدهای اچ تی ام ال */}
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">

                            <Header siteData={props.siteData} title='اخبار' back='true' />

                            <div className="visible-yscroll">

                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1 p-1">
                                            <input type="search" className="form-control editTextElement" value={search} placeholder="جستجو ... "
                                               onChange={(value) => {
                                                   setSearch(value.currentTarget.value);
                                                   searchForm();
                                               }}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">

                                            {
                                                news.map((item, index) => {
                                                    return (
                                                        <div className="item col-12">
                                                            <div className="card">
                                                                <img src={item.photo} alt={item.title} title={item.title} />
                                                                <span className="showDateSpan">{item.date}</span>
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{item.title}</h5>
                                                                    <Link to={'/news/detail/' + item.slug} className="btn btn-1 hover2">مشاهده خبر</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <TaskBar />
                    </div>
                </div>
            </div>
        </>
    )
}
export default News;
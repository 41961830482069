import React, { Component, useState, useEffect, memo } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";
import $ from 'jquery';
import axios from 'axios';
import useScript from './../../hook/useScript';
import Notification from './../../components/notification';
import { useCookies } from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import Loader from '../../components/loader';
import Field from '../../components/field';

//افزودن آگهی
const ModuleCarAddNeed = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    //بررسی اجازه نمایش صفحه
    if (props.siteData.moduleNeedAllowShow != 1)
        history.push("/");

    let adType = 2;
    let pageTitle = 'ثبت نیازمندی ها';

    { document.title = pageTitle }

    // ###############################
    // ###########       Set Data
    const [cookies, setCookie] = useCookies();
    const [type, setType] = useState(adType); //  نوع آگهی
    const [categories, setCategories] = useState([]);// برند ها
    const [subCategories, setSubCategories] = useState([]);// تیپ ها
    const [phone, setPhone] = useState('');// تلفن 
    const [name, setName] = useState('');// نام
    const [category_id, setCategory] = useState('');// شناسه برند
    const [subCategory_id, setSubCategory_id] = useState('');// شناسه تیپ
    const [address, setAddress] = useState('');// آدرس
    const [description, setDescription] = useState('');// توضیحات
    const [title, setTitle ] = useState('');
    const [needType, setNeedType ] = useState('');
    const [video, setVideo] = useState(''); // ویدیو
    const [photos, setPhoto] = useState([]);//  تصاویر

    const onChangePicture = async(e) => { setPhoto(e.target.files) };

    // ###############################
    // ذخیره
    const saveForm = async () => {
        // نمایش لودر
        Loader('show');

        // گرفتن داده های ورودی
        let data = new FormData();
        data.append("title", title);
        data.append("clip", video);
        data.append("name", name);
        data.append("phone", phone);
        data.append("type", type);
        data.append("category_id", category_id);
        data.append("subCategory_id", subCategory_id);
        data.append("address", address);
        data.append("description", description);
        data.append("needType", needType);
        
        
        // چون میخواهیم چند تصویر را ارسال کنیم و هر کدام نام مشخص داشته باشند با روش زیر آنها را از هم جدا کردیم
        let arra= Object.values(photos);
        await arra.forEach((item,index)=>{
            data.append("uploadPhoto"+index, item);
        })

        // تنظیم مسیر درخواست
        const url = process.env.REACT_APP_URL + '/api/v1/moduleCar/need/store';
        axios({
            method: 'post', url: url,
            data,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (res.data.status == 1) {
                    Notification('success', res.data.data);

                    // انتقال صفحه به صفحه لیست مقالات من
                    setTimeout(function () {
                        history.push("/moduleNeed");
                    }, 500);

                } else {
                    const resData = res.data.data;
                    if (Array.isArray(resData)) {
                        res.data.data.forEach(function (currentValue, index, arr) {
                            Notification('error', currentValue);
                        })
                    } else
                        Notification('error', resData);
                }
            }))
            .catch(error => {
                // درصورت بروز هر خطای این پیغام نمایش داده می شود
                if (error.response) {
                    Notification('error', "خطای پیش بینی نشده! لطفا دوباره امتحان کنید ");
                }
            })
        Loader('remove');
    }

    // ###############################
    // درخواست لیست برندها
    useEffect(() => {
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/moduleCar/category';
        axios({
            method: 'post', url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        }).then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    await setCategories(response.data.data)
                }
                Loader('remove');
            })
    }, []);

    // ###############################
    // درخواست لیست تیپ ها
    const handelTip = (categoryId) => {
        Loader('show');
        setSubCategories([]);
        setCategory(categoryId);
        const url = process.env.REACT_APP_URL + '/api/v1/moduleCar/category/childes';
        let data = new FormData();
        data.append('categoryId', categoryId);
        axios({
            method: 'post',
            url: url,
            data,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        }).then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    await setSubCategories(response.data.data)
                }
                Loader('remove');
            })
    };
    return (
        <>
            {/* کدهای اچ تی ام ال افزودن در صفحه بازارایاب  */}
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title={pageTitle} back='true' help="helpModuleCar" />
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            <section className="form-2">

                                                <div className="form-group">
                                                    <label htmlFor="name" className="form-label">نام و نام خانوادگی</label>
                                                    <input type="text" className="form-control inputElement" id='name' value={name}
                                                        onChange={(value) => {
                                                            setName(value.currentTarget.value)
                                                        }} />
                                                    <i className="fas fa-user beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="phone" className="form-label">تلفن </label>
                                                    <Field placeholder='مثال 34567899 (021) ' type='number' format='(###) ########' className="form-control inputElement pr-5" id='phone' value={phone} change={(val) => { setPhone(val) }} />
                                                    <i className="fas fa-phone beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="address" className="form-label">آدرس</label>
                                                    <input type="text" className="form-control inputElement" id='address' value={address}
                                                           onChange={(value) => {
                                                               setAddress(value.currentTarget.value)
                                                           }} />
                                                    <i className="fas fa-map-marked beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="title" className="form-label">عنوان آگهی</label>
                                                    <input type="text" className="form-control inputElement" id='title' value={title}
                                                           onChange={(value) => {
                                                               setTitle(value.currentTarget.value)
                                                           }} />
                                                    <i className="	fa fa-terminal beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="needType" className="form-label">نوع نیازمندی</label>
                                                    <select className="form-control inputElement" id="needType" defaultValue={String(needType)}
                                                            onChange={(value) => {
                                                                setNeedType(value.currentTarget.value);
                                                            }}>
                                                        <option value='0' selected={'0' == needType}>درخواست خدمات</option>
                                                        <option value='1' selected={'1' == needType}>ارائه خدمات</option>
                                                    </select>
                                                    <i className="fa fa-diamond beforeElement e2"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="category_id" className="form-label">دسته بندی</label>
                                                    <select className="form-control inputElement" id="category_id" defaultValue={String(category_id)}
                                                        onChange={(value) => {
                                                            handelTip(value.currentTarget.value);
                                                        }}>
                                                        <option value={0} selected={category_id == 0}>انتخاب دسته بندی</option>
                                                        {
                                                            categories.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.id} selected={category_id == item.id}>{item.title}</option>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <i className="fas fa-city beforeElement e2"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="city" className="form-label">زیر شاخه</label>
                                                    <select className="form-control inputElement" id="category_id" defaultValue={String(subCategory_id)}
                                                        onChange={(value) => {
                                                            setSubCategory_id(value.currentTarget.value);
                                                        }}>
                                                        <option value={0} selected={subCategory_id == 0}>انتخاب زیر شاخه</option>
                                                        {
                                                            subCategories.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.id} selected={subCategory_id == item.id}>{item.title}</option>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <i className="fas fa-city beforeElement e2"></i>
                                                </div>

                                                <div className="form-group d-flex">
                                                    <input  type="file" className="form-control fileElement" multiple  accept="image/*" onChange={onChangePicture} />
                                                    <label htmlFor="title" className="form-label">  تصاویر <small> (حداکثر 6 تصویر و حجم هر فایل حداکثر 5000 کیلوبایت) </small></label>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="description" className="form-label">توضیحات </label>
                                                    <textarea className="form-control" id='description' onChange={(value) => {
                                                        setDescription(value.currentTarget.value)
                                                    }} >{description}</textarea>
                                                </div>

                                                <div className="">
                                                    <button className="btn btn-outline-info btn-1 hover2" onClick={saveForm}>ذخیره</button>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModuleCarAddNeed;
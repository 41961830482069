import React, {Component, useState, useEffect, memo} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import useScript from "../../hook/useScript";
import {useCookies} from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import Loader from '../../components/loader';
import Notification from "../../components/notification";

// لیست خودرو ها
const ModuleCarMoney = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    //بررسی اجازه نمایش صفحه
    if (props.siteData.moduleMoneyAllowShow != 1)
        history.push("/");

    Loader('show');
    {document.title = 'لیست درخواست های ارزی من'}

    const [cookies, setCookie] = useCookies();
    const [myRequests, setMyRequests] = useState([])


    // ###############################
    // درخواست همه
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/moduleMoney/request/my';
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setMyRequests(response.data.data);
                } else {
                    Notification('error', response.data.data);
                }

                Loader('remove');
            })
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title='لیست درخواست های من' back='true' help="helpModuleMoney"/>
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            <div className="moduleCarItems all d-flex">
                                                <div className="col-5 item">
                                                    <div className="card">
                                                        <Link to='/moduleMoney/add' className="text-center">
                                                            <h5>ثبت درخواست ارز</h5>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                myRequests.map((item, index) => {
                                                    return (
                                                        <div className="col-12 item">
                                                            <div className="card">

                                                                <span className="showDateSpan">{item.date}</span>

                                                                <div className="card-body" style={{padding:'10px'}}>
                                                                    <h5 style={{margin:'10px 0'}}><b>ارز درخواستی : </b> <span>{item.money}</span> </h5>
                                                                    <h5 style={{margin:'10px 0'}}><b>میزان درخواستی : </b> <span>{item.amount}</span> </h5>
                                                                    <h5 style={{margin:'10px 0'}}><b>نحوه تحویل : </b> <span>{item.delivery}</span> </h5>
                                                                    <h5 style={{margin:'10px 0'}}><b>بانک قبرس : </b> <span>{item.bankNumber}</span> </h5>
                                                                    <h5 style={{margin:'10px 0'}}><b>وضعیت درخواست : </b> <span>{item.status}</span> </h5>
                                                                    <h5 style={{margin:'10px 0'}}><b>پرداختی : </b> <span>{item.pay} تومان </span> </h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModuleCarMoney;
import React, {Component, useState, useEffect, memo} from 'react';
import axios from 'axios';
import useScript from './../hook/useScript';

import Notification from './../components/notification';
import {BrowserRouter as Router, Link, useHistory} from 'react-router-dom';
import {useCookies} from "react-cookie";
import Loader from '../components/loader';
import ActiveMenuIcon from '../components/activeMenuIcon';

const Header = (props) => {

    // هندل کردن منو فعال
    ActiveMenuIcon();

    // برای جابجایی بین صفحات
    const history = useHistory();
    const [cookies, setCookie] = useCookies();
    const [userScore, setUserScore] = useState('');

    // بررسی لاگین نبودن کاربر و انتقتال به صفحه لاگین
    if (!cookies.tokenForAccess)
        history.push("/login");

    const [userCookie, setUserCookie] = useCookies(['id', 'mobile', 'tokenForAccess', 'firstName', 'lastName']);

    // ست کردن تنظیمات باشگاه
    const [siteData, setSiteData] = useState({
        'logo': '',
        'siteName': '',
        'userAvatar': '',
        'mainBackground': '',
        'menuBackground': '',
        'appBackground': '',
    });
    useEffect(() => {
        if (props.siteData) {
            setSiteData({
                'logo': props.siteData.logo,
                'siteName': props.siteData.siteName,
                'userAvatar': props.siteData.userAvatar,
                'mainBackground': props.siteData.mainBackground,
                'menuBackground': props.siteData.menuBackground,
                'appBackground': props.siteData.appBackground,
            });
        }
    }, [props.siteData])


    // ###############################
    //  ست کردن پس زمینه صفحه
    useEffect(() => {
        if (cookies.tokenForAccess) {
            if (siteData) {
                let body = document.querySelector("body");
                body.classList.add("user-role");
                // body.style.background = 'transparent url(' + siteData.mainBackground + ') no-repeat center/cover';
            }
        }
    }, [siteData])


    // ###############################
    //  درخواست خروج
    const logout = () => {
        const url = process.env.REACT_APP_URL + '/api/v1/user/logout';
        axios({
            method: 'post', url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        }).then((res => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (res.data.status == 1) {
                    // پاک کردن اطلاعات کاربر از کوکی مرورگر
                    let today = new Date(new Date);
                    today.setDate(today.getDate())
                    setUserCookie('id', '', {path: '/', expires: today})
                    setUserCookie('mobile', '', {path: '/', expires: today})
                    setUserCookie('tokenForAccess', '', {path: '/', expires: today})
                    setUserCookie('firstName', '', {path: '/', expires: today})
                    setUserCookie('lastName', '', {path: '/', expires: today})
                    history.push("/");
                } else {
                    Notification('error', res.data.data);
                }
            }))
    }

    // ###############################
    // راهنمای صفحه
    const [help, setHelp] = useState('');


    // ###############################
    //  درخواست برای راهنمای صفحه
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/pageHelp';
        let data = new FormData();
        data.append("token", cookies.tokenForAccess);
        data.append("key", props.help);
        axios({
            method: 'post',
            url: url,
            data,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    setHelp(response.data.data.help);
                    setUserScore(response.data.data.userScore);
                }
            })
    }, []);

    return (
        <>
            <img src={siteData.mainBackground} id='mainBackgroundImgTag' alt=""/>
            <header className="row mobileHeader">

                <Link to="/">
                    <img src={siteData.logo}/>
                </Link>

                <h1>{props.title}</h1>

                {props.back == 'true' && (<i className="fas fa-arrow-left redirectBackArrow" onClick={() => history.goBack()}></i>)}
                {props.help && (<i className='fa fa-question-circle headerHelpBtn' data-toggle="modal" data-target="#helpModal"></i>)}

                {/*  منو باز شونده  */}
                <div className="menu-tab">
                    <div id="one"></div>
                    <div id="two"></div>
                    <div id="three"></div>
                </div>
                <div className="menu-hide">
                    <nav>
                        <ul>
                            <li>میزان امتیاز شما : {userScore} </li>
                            {props.siteData.eventAllowShow == 1 && (<li><Link to="/event/my">رویدادهای  من</Link></li>)}

                            {/*   هرکدام که اجازه نمابش داشته باشند را نمایش میدهد  */}
                            {props.siteData.awardsAllowShow == 1 && (<li><Link to="/my/awards">جوایز من</Link></li>)}
                            {props.siteData.charitiesAllowShow == 1 && (<li><Link to="/my/charities"> خیریه من</Link></li>)}
                            {props.siteData.friendsAllowShow == 1 && (<li><Link to="/my/friends"> دوستان من</Link></li>)}
                            {props.siteData.marketerAllowShow == 1 && (<li><Link to="/marketers"> بازاریابان من</Link></li>)}
                            {props.siteData.factorsAllowShow == 1 && (<li><Link to="/my/factors">خرید های من</Link></li>)}
                            {props.siteData.warrantyAllowShow == 1 && (<li><Link to="/warranty">وارانتی و گارانتی من</Link></li>)}
                            {props.siteData.formAllowShow == 1 && (<li><Link to="/my/forms">فرم های من</Link></li>)}
                            {props.siteData.lotteriesAllowShow == 1 && (<li><Link to="/my/lotteries">قرعه کشی های من</Link></li>)}
                            {props.siteData.articlesAllowShow == 1 && (<li><Link to="/my/articles">مقالات من</Link></li>)}
                            {props.siteData.gameAllowShow == 1 && (<li><Link to="/my/games">مسابقات من</Link></li>)}
                            {props.siteData.banksAllowShow == 1 && (<li><Link to="/my/banks">درخواست های شارژ نقدی</Link></li>)}
                            {props.siteData.partnersAllowShow == 1 && (<li><Link to="/partner/register">شریک تجاری</Link></li>)}
                            {props.siteData.partnersAllowShow == 1 && (<li><Link to="/partner/users"> مشتریان</Link></li>)}
                            <li><Link to="/my/changePass">تغییر رمز عبور</Link></li>
                            <hr style={{margin: '5px 0 0 0', backgroundColor: '#bfcbcc'}}/>
                            <li><a onClick={() => logout()}>خروج</a></li>
                        </ul>
                    </nav>
                </div>
            </header>

            {/* مودال راهنما */}
            <div className="modal fade" id="helpModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <h2 className="modal-title">راهنما </h2>
                        <button type="button" className="close" data-dismiss="modal">
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close</span>
                        </button>
                        <div className="modal-body">
                            <div dangerouslySetInnerHTML={{__html: help}}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Header;
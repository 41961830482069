import React, { Component, useState, useEffect, memo } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";
import $ from 'jquery';
import axios from 'axios';
import useScript from './../../hook/useScript';
import Notification from './../../components/notification';
import { useCookies } from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import Loader from '../../components/loader';
import Field from '../../components/field';
import {DatePicker} from "react-advance-jalaali-datepicker";


//افزودن آگهی
const ModuleCarAuctionAdd = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    //بررسی اجازه نمایش صفحه
    if (props.siteData.moduleCarAllowShow != 1)
        history.push("/");

    let adType = 1;
    let pageTitle = 'ثبت مزایده خودرو';

    { document.title = pageTitle }

    // ###############################
    // ###########       Set Data
    const [cookies, setCookie] = useCookies();
    const [type, setType] = useState(adType); //  نوع آگهی
    const [brands, setBrands] = useState([]);// برند ها
    const [tips, setTips] = useState([]);// تیپ ها
    const [phone, setPhone] = useState('');// تلفن 
    const [name, setName] = useState('');// نام
    const [change, setChange] = useState('');// نصاب
    const [brand_id, setBrand] = useState('');// شناسه برند
    const [tip_id, setTip] = useState('');// شناسه تیپ
    const [address, setAddress] = useState('');// آدرس
    const [description, setDescription] = useState('');// توضیحات
    const [title, setTitle ] = useState('');
    const [model, setModel ] = useState('');
    const [fuel, setFuel ] = useState('');
    const [gearbox, setGearbox ] = useState('');
    const [color, setColor ] = useState('');
    const [work, setWork ] = useState('');
    const [body_status, setBody_status ] = useState('');
    const [engine_status, setEngine_status ] = useState('');
    const [gearbox_status, setGearbox_status ] = useState('');
    const [price, setPrice ] = useState('');
    const [third_party_insurance, setThird_party_insurance ] = useState('');
    const [body_insurance, setBody_insurance ] = useState('');
    const [payment_method, setPayment_method ] = useState('');
    const [delivery_time, setDelivery_time ] = useState('');
    const [testingTime, setTestingTime] = useState('');
    const [deductions_0, setDeductions_0] = useState([]);
    const [deductions_1, setDeductions_1] = useState([]);
    const [video, setVideo] = useState(''); // ویدیو
    const [photos, setPhoto] = useState([]);//  تصاویر
    const [startDate, setStartDate] = useState('');// تاریخ شروع
    const [endDate, setEndDate] = useState('');// تاریخ پایان

    const onChangePicture = async(e) => { setPhoto(e.target.files) };

    // ###############################
    // ذخیره
    const saveForm = async () => {
        // نمایش لودر
        Loader('show');

        // گرفتن داده های ورودی
        let data = new FormData();
        data.append("title", title);
        data.append("clip", video);
        data.append("name", name);
        data.append("phone", phone);
        data.append("type", type);
        data.append("brand_id", brand_id);
        data.append("tip_id", tip_id);
        data.append("change", change);
        data.append("address", address);
        data.append("description", description);
        data.append("model", model);
        data.append("fuel", fuel);
        data.append("gearbox", gearbox);
        data.append("color", color);
        data.append("work", work);
        data.append("body_status", body_status);
        data.append("engine_status", engine_status);
        data.append("gearbox_status", gearbox_status);
        data.append("price", price);
        data.append("third_party_insurance", third_party_insurance);
        data.append("body_insurance", body_insurance);
        data.append("payment_method", payment_method);
        data.append("delivery_time", delivery_time);
        data.append("testingTime", testingTime);
        data.append("deductions_0", deductions_0);
        data.append("deductions_1", deductions_1);
        data.append("startDate", startDate);
        data.append("endDate", endDate);

        // چون میخواهیم چند تصویر را ارسال کنیم و هر کدام نام مشخص داشته باشند با روش زیر آنها را از هم جدا کردیم
        let arra= Object.values(photos);
        await arra.forEach((item,index)=>{
            data.append("uploadPhoto"+index, item);
        })

        // تنظیم مسیر درخواست
        const url = process.env.REACT_APP_URL + '/api/v1/moduleCar/car/store';
        axios({
            method: 'post', url: url,
            data,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (res.data.status == 1) {
                    Notification('success', res.data.data);

                    // انتقال صفحه به صفحه لیست مقالات من
                    setTimeout(function () {
                        history.push("/moduleCar/auctions");
                    }, 500);

                } else {
                    Notification('error', res.data.data);
                    // بستنن لودر
                    Loader('remove');
                }
            }))
            .catch(error => {
                // درصورت بروز هر خطای این پیغام نمایش داده می شود
                if (error.response) {
                    Notification('error', "خطای پیش بینی نشده! لطفا دوباره امتحان کنید ");
                }
                Loader('remove');
            })
    }

    // ###############################
    // درخواست لیست برندها
    useEffect(() => {
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/moduleCar/brand';
        axios({
            method: 'post', url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        }).then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    await setBrands(response.data.data)
                }
                Loader('remove');
            })
    }, []);

    // ###############################
    // درخواست لیست تیپ ها
    const handelTip = (brandId) => {
        Loader('show');
        setTips([]);
        setBrand(brandId);
        const url = process.env.REACT_APP_URL + '/api/v1/moduleCar/brand/childes';
        let data = new FormData();
        data.append('brandId', brandId);
        axios({
            method: 'post',
            url: url,
            data,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        }).then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    await setTips(response.data.data)
                }
                Loader('remove');
            })
    };


    // هندل کردن تاریخ
    const handelStartDateTimePiker = (timeStamp, persianDate) => {
        setStartDate(persianDate);
    }
    const handelEndDateTimePiker = (timeStamp, persianDate) => {
        setEndDate(persianDate);
    }

    return (
        <>
            {/* کدهای اچ تی ام ال افزودن در صفحه بازارایاب  */}
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title={pageTitle} back='true' help="helpModuleCar" />
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            <section className="form-2">

                                                <div className="form-group">
                                                    <label htmlFor="name" className="form-label">نام و نام خانوادگی</label>
                                                    <input type="text" className="form-control inputElement" id='name' value={name}
                                                        onChange={(value) => {
                                                            setName(value.currentTarget.value)
                                                        }} />
                                                    <i className="fas fa-user beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="phone" className="form-label">تلفن </label>
                                                    <Field placeholder='مثال 34567899 (021) ' type='number' format='(###) ########' className="form-control inputElement pr-5" id='phone' value={phone} change={(val) => { setPhone(val) }} />
                                                    <i className="fas fa-phone beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="address" className="form-label">آدرس</label>
                                                    <input type="text" className="form-control inputElement" id='address' value={address}
                                                           onChange={(value) => {
                                                               setAddress(value.currentTarget.value)
                                                           }} />
                                                    <i className="fas fa-map-marked beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="title" className="form-label">عنوان آگهی</label>
                                                    <input type="text" className="form-control inputElement" id='title' value={title}
                                                           onChange={(value) => {
                                                               setTitle(value.currentTarget.value)
                                                           }} />
                                                    <i className="	fa fa-terminal beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="date" className="form-label">تاریخ شروع</label>
                                                    <DatePicker
                                                        placeholder=""
                                                        format="jYYYY/jMM/jDD"
                                                        onChange={handelStartDateTimePiker}
                                                        id="persianDatePicker1"
                                                        preSelected={startDate}
                                                    />
                                                    <i className="fa fa-calendar beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="date" className="form-label">تاریخ پایان</label>
                                                    <DatePicker
                                                        placeholder=""
                                                        format="jYYYY/jMM/jDD"
                                                        onChange={handelEndDateTimePiker}
                                                        id="persianDatePicker1"
                                                        preSelected={endDate}
                                                    />
                                                    <i className="fa fa-calendar beforeElement"></i>
                                                </div>



                                                <div className="form-group">
                                                    <label htmlFor="brand_id" className="form-label">برند</label>
                                                    <select className="form-control inputElement" id="brand_id" defaultValue={String(brand_id)}
                                                        onChange={(value) => {
                                                            handelTip(value.currentTarget.value);
                                                        }}>
                                                        <option value={0} selected={brand_id == 0}>انتخاب برند</option>
                                                        {
                                                            brands.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.id} selected={brand_id == item.id}>{item.title}</option>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <i className="fas fa-city beforeElement e2"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="city" className="form-label">تیپ</label>
                                                    <select className="form-control inputElement" id="brand_id" defaultValue={String(tip_id)}
                                                        onChange={(value) => {
                                                            setTip(value.currentTarget.value);
                                                        }}>
                                                        <option value={0} selected={tip_id == 0}>انتخاب تیپ</option>
                                                        {
                                                            tips.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.id} selected={tip_id == item.id}>{item.title}</option>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <i className="fas fa-city beforeElement e2"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="model" className="form-label">مدل</label>
                                                    <input type="number" className="form-control inputElement" id='model' value={model}
                                                           onChange={(value) => {
                                                               setModel(value.currentTarget.value)
                                                           }} />
                                                    <i className="fa fa-modx beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="price" className="form-label">شروع قیمت</label>
                                                    <input type="number" className="form-control inputElement" id='price' value={price}
                                                           onChange={(value) => {
                                                               setPrice(value.currentTarget.value)
                                                           }} />
                                                    <i className="fa fa-money beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="fuel" className="form-label">سوخت</label>
                                                    <select className="form-control inputElement" id="fuel" defaultValue={String(fuel)}
                                                            onChange={(value) => {
                                                                setFuel(value.currentTarget.value);
                                                            }}>
                                                        <option value='0' selected={'0' == fuel}>بنزین</option>
                                                        <option value='1' selected={'1' == fuel}>دوگانه سوز</option>
                                                        <option value='2' selected={'2' == fuel}>هیبریدی</option>
                                                        <option value='3' selected={'3' == fuel}>برقی</option>
                                                        <option value='4' selected={'4' == fuel}>گازوئیلی</option>
                                                    </select>
                                                    <i className="fas fa-oil-can beforeElement e2"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="gearbox" className="form-label">نوع گیربکس</label>
                                                    <select className="form-control inputElement" id="gearbox" defaultValue={String(gearbox)}
                                                            onChange={(value) => {
                                                                setGearbox(value.currentTarget.value);
                                                            }}>
                                                        <option value='0' selected={'0' == gearbox}>دنده ای</option>
                                                        <option value='1' selected={'1' == gearbox}>اتوماتیک</option>
                                                    </select>
                                                    <i className="fab fa-steam beforeElement e2"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="gearbox_status" className="form-label">وضعیت گیبرکس</label>
                                                    <input type="text" className="form-control inputElement" id='gearbox_status' value={gearbox_status}
                                                           onChange={(value) => {
                                                               setGearbox_status(value.currentTarget.value)
                                                           }} />
                                                    <i className="fa fa-calendar-check-o beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="color" className="form-label">رنگ</label>
                                                    <input type="text" className="form-control inputElement" id='color' value={color}
                                                           onChange={(value) => {
                                                               setColor(value.currentTarget.value)
                                                           }} />
                                                    <i className="fas fa-bowling-ball beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="work" className="form-label">میزان کارکرد</label>
                                                    <input type="number" className="form-control inputElement" id='work' value={work}
                                                           onChange={(value) => {
                                                               setWork(value.currentTarget.value)
                                                           }} />
                                                    <i className="fas fa-car-side beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="body_status" className="form-label">وضعیت بدنه</label>
                                                    <input type="text" className="form-control inputElement" id='body_status' value={body_status}
                                                           onChange={(value) => {
                                                               setBody_status(value.currentTarget.value)
                                                           }} />
                                                    <i className="fa fa-calendar-check-o beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="engine_status" className="form-label">وضعیت موتور</label>
                                                    <input type="text" className="form-control inputElement" id='engine_status' value={engine_status}
                                                           onChange={(value) => {
                                                               setEngine_status(value.currentTarget.value)
                                                           }} />
                                                    <i className="fa fa-calendar-check-o beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="third_party_insurance" className="form-label"> بیمه شخص ثالث</label>
                                                    <input type="text" className="form-control inputElement" id='third_party_insurance' value={third_party_insurance}
                                                           onChange={(value) => {
                                                               setThird_party_insurance(value.currentTarget.value)
                                                           }} />
                                                    <i className="fas fa-car beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="body_insurance" className="form-label"> بیمه بنده</label>
                                                    <input type="text" className="form-control inputElement" id='body_insurance' value={body_insurance}
                                                           onChange={(value) => {
                                                               setBody_insurance(value.currentTarget.value)
                                                           }} />
                                                    <i className="fas fa-car-crash beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="payment_method" className="form-label"> نحوه پرداخت </label>
                                                    <input type="text" className="form-control inputElement" id='payment_method' value={payment_method}
                                                           onChange={(value) => {
                                                               setPayment_method(value.currentTarget.value)
                                                           }} />
                                                    <i className="fa fa-dollar beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="delivery_time" className="form-label">زمان تحویل (روز) </label>
                                                    <input type="number" className="form-control inputElement" id='delivery_time' value={delivery_time}
                                                           onChange={(value) => {
                                                               setDelivery_time(value.currentTarget.value)
                                                           }} />
                                                    <i className="fas fa-dna beforeElement"></i>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="testingTime" className="form-label"> زمان تست (روز) </label>
                                                    <input type="number" className="form-control inputElement" id='testingTime' value={testingTime}
                                                           onChange={(value) => {
                                                               setTestingTime(value.currentTarget.value)
                                                           }} />
                                                    <i className="fas fa-business-time beforeElement"></i>
                                                </div>

                                                <div className="form-group d-flex">
                                                    <input  type="file" className="form-control fileElement" multiple  accept="image/*" onChange={onChangePicture} />
                                                    <label htmlFor="title" className="form-label">  تصاویر <small> (حداکثر 6 تصویر و حجم هر فایل حداکثر 5000 کیلوبایت) </small></label>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="deductions_0" className="form-label">کسورات (با - از هم جدا شوند) </label>
                                                    <textarea className="form-control" id='deductions_0' onChange={(value) => {
                                                        setDeductions_0(value.currentTarget.value)
                                                    }} >{deductions_0}</textarea>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="deductions_1" className="form-label">اضافات (با - از هم جدا شوند)  </label>
                                                    <textarea className="form-control" id='deductions_1' onChange={(value) => {
                                                        setDeductions_1(value.currentTarget.value)
                                                    }} >{deductions_1}</textarea>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="description" className="form-label">توضیحات </label>
                                                    <textarea className="form-control" id='description' onChange={(value) => {
                                                        setDescription(value.currentTarget.value)
                                                    }} >{description}</textarea>
                                                </div>

                                                <div className="">
                                                    <button className="btn btn-outline-info btn-1 hover2" onClick={saveForm}>ذخیره</button>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModuleCarAuctionAdd;
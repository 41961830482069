import React, { Component, useState, useEffect, memo } from 'react';
import axios from 'axios';
import {BrowserRouter as Router, Link, useHistory} from 'react-router-dom';
import { useCookies } from 'react-cookie';

const Sidebar = (props) => {
    const history = useHistory();
    const [cookies, setCookie] = useCookies();
    const [userCookie, setUserCookie] = useCookies(['id', 'mobile', 'tokenForAccess', 'firstName', 'lastName']);

    const [siteData, setSiteData] = useState({
        'logo': '',
        'siteName': '',
        'userAvatar': '',
        'mainBackground': '',
        'menuBackground': '',
        'appBackground': '',
    });
    useEffect(() => {
        if (props.siteData) {
            setSiteData({
                'logo': props.siteData.logo,
                'siteName': props.siteData.siteName,
                'userAvatar': props.siteData.userAvatar,
                'mainBackground': props.siteData.mainBackground,
                'menuBackground': props.siteData.menuBackground,
                'appBackground': props.siteData.appBackground,
            });
        }
    }, [props.siteData])


    // useEffect(() => {
    //
    //     if (siteData.menuBackground != '' && siteData.menuBackground != undefined) {
    //         var aside = document.querySelector("aside.main-sidebar");
    //         aside.style.background = 'transparent url(' + siteData.menuBackground + ') no-repeat center/contain';
    //     }
    //
    // }, [siteData.menuBackground])


    const logout = () => {
        const url = process.env.REACT_APP_URL + '/api/v1/user/logout';
        axios({
            method: 'post', url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res => {
                if (res.data.status == 1) {
                    let today = new Date(new Date);
                    today.setDate(today.getDate())
                    setUserCookie('id', '', {path: '/', expires: today})
                    setUserCookie('mobile', '', {path: '/', expires: today})
                    setUserCookie('tokenForAccess', '', {path: '/', expires: today})
                    setUserCookie('firstName', '', {path: '/', expires: today})
                    setUserCookie('lastName', '', {path: '/', expires: today})
                    history.push("/");
                } else {
                    Notification('error', res.data.data);
                }
            }))
    }


    return (


        <aside className="main-sidebar">
            <img src={ siteData.menuBackground } id='main-sidebar-image-tag' alt=""/>
            <section className="sidebar">
                <ul className="sidebar-menu">
                    <li className="item">
                        <Link to="/" className="logo-bg">
                            {/* <img src={siteData.logo} className="img-fluid" /> */}
                        </Link>
                    </li>

                    <li className="item">
                        <Link to="/" data-link='http://club.azaran-group.com/' title="خانه">
                            <i className="fa fa-home"></i>
                        </Link>
                    </li>

                    <li className="item">
                        <Link to="/my/scoreHistory" data-link='http://club.azaran-group.com/my/scoreHistory' title="گزارشات">
                            <i className="fa fa-list"></i>
                        </Link>
                    </li>

                    <li className="item">
                        <Link to="/faq" data-link='http://club.azaran-group.com/faq' title="سوالات متداول">
                            <i className="fa fa-question-circle"></i>
                        </Link>
                    </li>

                    <li className="item">
                        <Link to="/my/profile" data-link='http://club.azaran-group.com/my/profile' title="پروفایل">
                            <i className="far fa-user-circle"></i>
                        </Link>
                    </li>


                    <li className="item">
                        <a title="خروج" onClick={() => logout()}>
                            <i className="fas fa-sign-out-alt"></i>
                        </a>
                    </li>

                </ul>
            </section>
        </aside>


    );
}
export default Sidebar;




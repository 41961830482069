import React, {Component, useState, useEffect, memo} from 'react';
import $ from 'jquery';
import axios from 'axios';
import reactDom from 'react-dom';
import useScript from './../../hook/useScript';
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import {render} from "react-dom";
import Notification from './../../components/notification';
import {useCookies} from "react-cookie";
import Loader from '../../components/loader';
// import { DatePicker } from "jalali-react-datepicker";

import {
    DatePicker,
    DateTimePicker,
    DateRangePicker,
    DateTimeRangePicker
} from "react-advance-jalaali-datepicker";
import Field from '../../components/field';


// پروفایل
const Profile = (props) => {
    {
        document.title = 'پروفایل'
    }
    const [cookies, setCookie] = useCookies();
    const [firstName, setFirstName] = useState(''); // نام
    const [lastName, setLastName] = useState(''); // فامیل
    const [mobile, setMobile] = useState(''); // موبایل
    const [phone, setPhone] = useState('');// تلفن
    const [photo, setPhoto] = useState('');// تصویر
    const [address, setAddress] = useState('');// آدرس

    //################################
    //   درخواست پروفایل
    useEffect(() => {
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/user/userProfile';
        axios({
            method: 'post', url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                if (response.data.status == 1) {
                    await setFirstName(response.data.data.userInfo.firstName)
                    await setLastName(response.data.data.userInfo.lastName)
                    await setMobile(response.data.data.userInfo.mobile)
                    await setPhoto(response.data.data.userInfo.photo)
                    await setPhone(response.data.data.userInfo.phone)
                    await setAddress(response.data.data.userInfo.address)
                }
                Loader('remove');
            }).catch(async (error) => {
            if (error.response) {
                if (error.response.status == 401) {
                    window.location.href = '/'
                }
                Loader('remove');
            }
            console.log(error)
        })
    }, []);


    //#############################
    //   بروزرسانی
    const updateUserInformation = async () => {
        Loader('show');
        let data = new FormData();
        data.append('firstName', firstName);
        data.append('lastName', lastName);
        data.append('phone', phone);
        data.append('address', address);

        const url = process.env.REACT_APP_URL + '/api/v1/user/update';
        axios({
            method: 'post', url: url,
            data,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (res.data.status == 1) {
                    Notification('success', res.data.data);
                } else {
                    const resData = res.data.data;
                    if (Array.isArray(resData)) {
                        res.data.data.forEach(function (currentValue, index, arr) {
                            Notification('error', currentValue);
                        })
                    } else
                        Notification('error', resData);
                }
                Loader('remove');
            }))
            .catch(error => {
                // بروز خطا از سرور
                if (error.response) {
                    Notification('error', 'خطای پیش بینی نشده . لطفا کمی بعد مجددا امتحان فرمائید');
                    Loader('remove');
                }
            })
    }

    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title='پروفایل' back='true' help="helpProfile"/>
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            <section className="form-2" style={{padding: '10px'}}>
                                                    <div className="form-group">
                                                        <label htmlFor="firstName" className="form-label">نام</label>
                                                        <input type="text" className="form-control inputElement"
                                                               id='firstName'
                                                               onChange={(value) => {
                                                                   setFirstName(value.currentTarget.value)
                                                               }} value={firstName}/>
                                                        <i className="fas fa-user beforeElement"></i>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="lastName" className="form-label">نام خانوادگی</label>
                                                        <input type="text" className="form-control inputElement"
                                                               id='lastName'
                                                               onChange={(value) => {
                                                                   setLastName(value.currentTarget.value)
                                                               }} value={lastName}/>
                                                        <i className="fas fa-user beforeElement"></i>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="mobile" className="form-label">تلفن همراه</label>
                                                        <Field placeholder='تلفن همراه' type='number'
                                                               change={(value) => {
                                                                   setMobile(value)
                                                               }} className="form-control inputElement pr-5" id="mobile"
                                                               value={mobile} format='###########'/>
                                                        <i className="fa fa-mobile beforeElement"></i>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="phone" className="form-label">تلفن ثابت</label>
                                                        <Field dir={phone ? 'ltr' : 'rtl'} placeholder='تلفن ثابت'
                                                               type='number' change={(value) => {
                                                            setPhone(value)
                                                        }} className="form-control inputElement pr-5 " id="phone"
                                                               value={phone} format='(###) ########'/>
                                                        <i className="fas fa-phone beforeElement"></i>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="address" className="form-label">آدرس</label>
                                                        <input type="text" className="form-control inputElement"
                                                               id='address' value={address}
                                                               onChange={(value) => {
                                                                   setAddress(value.currentTarget.value)
                                                               }}/>
                                                        <i className="	fa fa-map-marker beforeElement"></i>
                                                    </div>

                                                <div className="">
                                                    <button className="btn btn-outline-info btn-1 hover2"
                                                            onClick={updateUserInformation}>ذخیره
                                                        اطلاعات
                                                    </button>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Profile;
import React, {useEffect, useState} from 'react';
import {Bar , Line} from 'react-chartjs-2';
import axios from "axios";
import {useCookies} from "react-cookie";

const GroupedBar = () => {
    const [cookies, setCookie] = useCookies();

    const [responseData, setResponseData] = useState([]);
    const [dates, setDates] = useState([]);
    const [scores, setScore] = useState([]);

    //   Get Data
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/user/scoresChart';
        axios({
            method: 'post', url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        }).then(async (response) => {
            if (response.data.status === 1) {
                // await setResponseData(response.data.data);
                let responseData = response.data.data;

                let arrDate = [] ;
                let arrScore = [] ;
                responseData.map((item, index) => {
                    arrDate.push(item.day);
                    arrScore.push(item.score);
                })
            
                setDates(arrDate);
                setScore(arrScore);
            }
        })
    }, []);






    const data = {
        labels: dates,
        datasets: [
            {
                label: 'نمودار موجودی',
                data: scores ,
                backgroundColor: 'rgb(54,235,184)',
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };


    return (
        <>
            {/* <Bar data={data} options={options}/> */}
            <Line data={data} options={options}/>
        </>
    );
};

export default GroupedBar;

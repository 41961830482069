import React, { Component, useState, useEffect, memo } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    Link,
    useParams
} from "react-router-dom";


import axios from 'axios';
import Notification from './../../components/notification';
import { useCookies } from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import { Carousel } from 'react-responsive-carousel';
import Loader from '../../components/loader';


// صفحه جزئیات آگهی
const ModuleCarNeedDetail = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    // بررسی اینکه آیا اجازه نمایش این صفحه از پنل مدیریت داده شده است یا نه
    if (props.siteData.moduleNeedAllowShow != 1)
        history.push("/");


    Loader('show');
    {document.title = 'جزئیات آگهی'}


    // ###############################
    // ###########       Set Data
    const [cookies, setCookie] = useCookies(); // کوکی
    const [id, setId] = useState(useParams().id); // شناسه جدول
    const [title, setTitle] = useState(''); // عنوان
    const [date, setDate] = useState(''); // تاریخ
    const [category, setCategory ] = useState('');
    const [subCategory, setSubCategory ] = useState('');
    const [video, setVideo] = useState(''); // ویدیو
    const [photos, setPhoto] = useState([]);//  تصاویر
    const [description, setDescription] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');

    // ###############################
    // ###########m    ارسال درخواست به سرور برای دریافت آگهی مورد نظر
    useEffect(() => {
        const url1 = process.env.REACT_APP_URL + '/api/v1/moduleCar/need/detail';
        axios({
            method: 'post',
            data: { 'id': id },
            url: url1,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
            }
        })
            // بازگشت داده از سرور
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    // ست کردن داده های برگشتی
                    // const responseData = JSON.parse(response.data.data);
                    const responseData = response.data.data;
                    await setTitle(responseData.title)
                    await setDate(responseData.date)
                    await setVideo(responseData.video)
                    await setPhoto(responseData.photos)
                    await setCategory(responseData.category)
                    await setSubCategory(responseData.subCategory)
                    await setDescription(responseData.description)
                    await setPhone(responseData.phone)
                    await setAddress(responseData.address)
                } else {
                    Notification('error', response.data.data);
                }

                Loader('remove');
            }).catch(async (error) => {
                // درصئرت بروز هر خطایی این پیغام نمایش داده می شود
                if (error.response) {
                    if (error.response.status == 401) {
                        window.location.href = '/'
                    }
                    Loader('remove');
                }
            })
    }, []);

    return (
        <>
            <div className="row theAdDetailPage">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title={title} back='true' help="helpModuleCar"/>
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            <section className='form-2'>
                                                {photos[0] && (
                                                    <Carousel className='ltr' autoPlay={true} infiniteLoop={true} interval={8000} showArrows={false}
                                                        showIndicators={true}
                                                        showThumbs={false} showStatus={false} transitionTime={1000}>
                                                        {photos.map(item =>
                                                            <div>
                                                                <img src={item} alt='slider' title='slider' style={{ height: '200px' }} />
                                                            </div>
                                                        )}
                                                    </Carousel>
                                                )}
                                                {video && (
                                                    <>
                                                        <br /> <br />
                                                        <video id="player" style={{ width: '100%', height: ' 300px' }} playsinline controls data-poster={video}>
                                                            <source src={video} type="video/mp4" />
                                                            <source src={video} type="video/webm" />
                                                            <track kind="captions" label="English captions" src="/path/to/captions.vtt" srclang="en" default />
                                                        </video>
                                                    </>
                                                )}
                                                <br/>
                                                <h5>{title}</h5>
                                                <ul>
                                                    { phone != 'false' && (<li><b>تلفن فروشنده : </b> <span>{phone}</span></li>)}
                                                    { address != 'false' && (<li><b>آدرس فروشنده : </b> <span>{address}</span></li>)}
                                                    { category && (<li><b>دسته بندی : </b> <span>{category}</span></li>)}
                                                    { subCategory && (<li><b>زیرشاخه : </b> <span>{subCategory}</span></li>)}
                                                </ul>
                                                { description && (<p>{description}</p>)}
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModuleCarNeedDetail;
import React, { Component, useState, useEffect, memo } from 'react';
import axios from 'axios';
import GroupedBar from "../../components/GroupedBar";
import { useCookies } from "react-cookie";
import Header from "../../layouts/header";
import { Link } from "react-router-dom";
import TaskBar from "../../layouts/taskBar";
import Loader from '../../components/loader';
import $ from 'jquery'
import Notification from "../../components/notification";

//  تاریخچه کسب و خرج'
const ScoreHistory = (props) => {
    {
        document.title = 'تاریخچه کسب و خرج'
    }
    const [cookies, setCookie] = useCookies();

    const [scoreHistory, setScoreHistory] = useState([]);
    const [chart, setChart] = useState([]);
    const [displayList, setDisplayList] = useState('item col-12 displayViewIsGrid');


    // ###############################
    // درخواست تاریخچه
    useEffect(() => {
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/user/scores';
        axios({
            method: 'post', url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setScoreHistory(response.data.data);
                    Loader('remove');
                }
            });
    }, []);


    // نحوه نمایش لیست
    const howToShowList = (display) => {
        // گرید
        if (display == 'grid') {
            setDisplayList('item col-6 p-1');
            $('i.grid').addClass('active');
            $('i.list').removeClass('active');
        }
        // لیست
        else {
            setDisplayList('item col-12 displayViewIsGrid') ;
            $('i.list').addClass('active');
            $('i.grid').removeClass('active');
        }
    }

    // ###############################
    // درخواست جستجو
    const [type, setType] = useState('');
    const [sort, setSort] = useState('');
    const [item, setItem] = useState('');
    useEffect(() => {
        searchForm()
    }, [type,sort,item]);

    const searchForm = async () => {
        const url = process.env.REACT_APP_URL + '/api/v1/user/scores';
        let data = new FormData();
        data.append("item", item);
        data.append("type", type);
        data.append("sort", sort);

        axios({
            method: 'post',
            data,
            url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    await setScoreHistory(response.data.data);
                } else {
                    Notification('error', response.data.data);
                }
            }).catch(async (error) => {
            if (error.response) {
                if (error.response.status == 401) {
                    window.location.href = '/'
                }
            }

        })
    }

    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title='تاریخچه' back='true' help="helpSendScoreHistory" />
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1 row m-0 box-2 scoreHistory">
                                            <GroupedBar />
                                            <div className="row m-0 w-100">
                                                <div className="col-2 mt-5 howDisplayList p-1">
                                                    <i className="fas fa-th-large grid" onClick={()=>{howToShowList('grid')}}></i>
                                                    <i className="fas fa-th-list list active" onClick={()=>{howToShowList('list')}}></i>
                                                </div>
                                                <div className="col-3 mt-5 mx-auto p-0">
                                                    <select className="form-control" defaultValue={item} style={{'height': '34px' , "fontSize": "12px", 'padding': 0}}
                                                            onChange={(event) => {
                                                                setItem(event.currentTarget.value);
                                                            }}>
                                                        <option value='همه' selected={item == 'همه'}>همه</option>
                                                        <option value='مقاله' selected={item == 'مقاله'}>مقاله</option>
                                                        <option value='پروژه' selected={item == 'پروژه'}>پروژه</option>
                                                        <option value='مهارت' selected={item == 'مهارت'}>مهارت</option>
                                                        <option value='فاکتور' selected={item == 'فاکتور'}>فاکتور</option>
                                                        <option value='قرعه کشی' selected={item == 'قرعه کشی'}>قرعه کشی</option>
                                                        <option value='جایزه' selected={item == 'جایزه'}>جایزه</option>
                                                        <option value='پورسانت' selected={item == 'پورسانت'}>پورسانت</option>
                                                        <option value='فرم' selected={item == 'فرم'}>فرم</option>
                                                        <option value='مسابقه' selected={item == 'مسابقه'}>مسابقه</option>
                                                        <option value='گردونه' selected={item == 'گردونه'}>گردونه</option>
                                                        <option value='دوستان' selected={item == 'دوستان'}>دوستان</option>
                                                        <option value='خیریه' selected={item == 'خیریه'}>خیریه</option>
                                                    </select>
                                                </div>
                                                <div className="col-3 mt-5 mx-auto p-0">
                                                    <select className="form-control" defaultValue={type} style={{'height': '34px' , "fontSize": "12px", 'padding': 0}}
                                                            onChange={(event) => {
                                                                setType(event.currentTarget.value);
                                                            }}>
                                                        <option value='0'>کسر </option>
                                                        <option value='1'>کسب </option>
                                                    </select>
                                                </div>
                                                <div className="col-3 mt-5 p-0">
                                                    <select className="form-control" defaultValue={sort} style={{'height': '34px' , "fontSize": "12px", 'padding': 0}}
                                                            onChange={(event) => {
                                                                setSort(event.currentTarget.value);
                                                            }}>
                                                        <option value='asc' selected={sort == 'asc'}>نزولی</option>
                                                        <option value='desc' selected={sort == 'desc'}>صعودی</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                scoreHistory.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div key={item.id} className={displayList} data-toggle="modal" data-target={"#exampleVerticallycenteredModal_" + item.id}>
                                                                <div className="card">
                                                                    <img src={item.avatar} className="card-img-top" alt="avatar" title="avatar" />
                                                                    <div className="card-body min-height-114">

                                                                        <p className="mb-1">{item.description}</p>

                                                                        <p className={item.status == 0 ? 'b negative' : 'b posetive'}>{item.score} </p>

                                                                        <p className="m-0 date">{item.year} </p>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div key={'modal_' + item.id} className="modal fade" id={"exampleVerticallycenteredModal_" + item.id}
                                                                role="dialog">
                                                                <div className="modal-dialog">
                                                                    <div className="modal-content">
                                                                        <h2 className="modal-title">جزئیات بیشتر</h2>
                                                                        <button type="button" className="close" data-dismiss="modal">
                                                                            <span aria-hidden="true">×</span>
                                                                            <span className="sr-only">Close</span>
                                                                        </button>

                                                                        <div className="modal-body">
                                                                            <table className="table table-striped">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>نوع رویداد</th>
                                                                                        <td>{item.type}</td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <th>تعداد امتیاز</th>
                                                                                        <td>{item.score}</td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <th>تاریخ</th>
                                                                                        <td>{item.time + ' ' + item.year}</td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <th>بابت</th>
                                                                                        <td>{item.description}</td>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </>
                                                    )
                                                })
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                        <TaskBar />

                    </div>
                </div>
            </div>

        </>
    )
}
export default ScoreHistory;

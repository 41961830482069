import $ from 'jquery';

const ActiveMenuIcon = () => {

    let pageUrl = window.location;

    $('.main-sidebar .sidebar-menu li.item > a.active').removeClass('active');
    $('.main-sidebar .sidebar-menu li.item > a').each(function () {
       
        if (($(this).attr('data-link') + '/') == pageUrl || $(this).attr('data-link') == pageUrl) {
            $(this).addClass('active');
        }
    });

    
    $('.userTaskBar li.item > a.active').removeClass('active');
    $('.userTaskBar li.item > a').each(function () {
       
        if (($(this).attr('data-link') + '/') == pageUrl || $(this).attr('data-link') == pageUrl) {
            $(this).addClass('active');
        }
    });



};
export default ActiveMenuIcon;

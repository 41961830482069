import $ from 'jquery';

const Loader = (status) => {

    if (status == 'show')
        $('#spinnerElement').addClass('show');
    else
        $('#spinnerElement').removeClass('show');

    // setTimeout(function () {
    //     $('#spinnerElement').removeClass('show');
    //  }, 10000);

};
export default Loader;

import React, {Component, useState, useEffect, memo} from 'react';
import axios from 'axios';
import useScript from './../hook/useScript';
import Notification from './../components/notification';
import Header from "../layouts/header";
import TaskBar from "../layouts/taskBar";
import Loader from '../components/loader';

// صفحه درباره باشگاه
const AboutUs = (props) => {
    Loader('show');
    { document.title = 'درباره باشگاه' }

    //   Set Data
    const [data, setData] = useState([]);

    //  درخواست اطلاعات از سرور
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/pages/aboutUs';
        axios({
            method: 'post',
            url: url,
        }).then(async (response) => {
            if (response.data.status === 1) {
                await setData(response.data.data);
                Loader('remove');
            }
        })
    }, []);

    return (
        <>
            {/* کدهای اچ تی ام ال */}
            <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box">
                            <Header siteData={props.siteData} title='درباره باشگاه' back='true'/>
                            <div className="visible-yscroll">
                                <div style={{     backgroundColor:"#fff" , margin: 0 , display: 'flex' , padding: '15px' , textAlign: 'right' ,
                                  borderRadius:' 5px' , lineHeight: 2}}>
                                    <div dangerouslySetInnerHTML={{ __html: data }} />
                                </div>
                            </div>
                        </div>
                        <TaskBar/>
                    </div>
                </div>
            </div>

        </>
    )
}
export default AboutUs;

import React, {useRef, useState, useEffect} from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import Header from '../layouts/header';
import Sidebar from '../layouts/sidebar';
import TaskBar from '../layouts/taskBar';
import axios from 'axios';
import {useCookies} from "react-cookie";
import Loader from '../components/loader';
import * as url from "url";
import {Carousel} from "react-responsive-carousel";
import Notification from "../components/notification";
// import background from "react-advance-jalaali-datepicker/src/Partials/Background";

// صفحه خانه
const Home = (props) => {
    { document.title = 'باشگاه مشتریان' }

    const [cookies, setCookie] = useCookies();

    // تنظیمات سایت
    const [siteData, setSiteData] = useState({
        'logo': '',
        'siteName': '',
        'userAvatar': '',
        'mainBackground': '',
        'menuBackground': '',
        'appBackground': '',
    });
    useEffect(() => {
        if (props.siteData) {
            setSiteData({
                'logo': props.siteData.logo,
                'siteName': props.siteData.siteName,
                'userAvatar': props.siteData.userAvatar,
                'mainBackground': props.siteData.mainBackground,
                'menuBackground': props.siteData.menuBackground,
                'appBackground': props.siteData.appBackground,
                'gifCard': props.siteData.gifCard,
            });
        }
    }, [props.siteData])


    // ###############################
    // ###########       Set Data
    const [slider, setSlider] = useState([]);
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/slider';
        Loader('show');
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setSlider(response.data.data);
                }
                Loader('remove');
            })
            .catch(error => {
                // درصورت بروز هر خطای این پیغام نمایش داده می شود
                const resData = error.response.data.errors;
                if(typeof resData === 'object') {
                    for (const [key, value] of Object.entries(resData)) {
                        Notification('error', value);
                    }
                }else
                    Notification('error', 'خطای پیش بینی نشده . لطفا کمی بعد مجددا امتحان فرمائید');

                Loader('remove');
            })
    }, []);


    // ###############################
    const setPageTitleInStorage =(title)=>{
        localStorage.setItem('pageTitleInStorage', title);
    }

    // ###############################
    // تنظیمات قالب نام و آیکن های صفحه اول
    const [layout, setLayout] = useState({
        'scoreTitle': '',
        'scoreIcon': '',
        'scorePhoto': '',
        'forumTitle': '',
        'forumIcon': '',
        'forumPhoto': '',
        'commentsTitle': '',
        'commentsIcon': '',
        'commentsPhoto': '',
        'newsTitle': '',
        'newsIcon': '',
        'newsPhoto': '',
        'siteTitle': '',
        'siteIcon': '',
        'sitePhoto': '',
        'instagramTitle': '',
        'instagramIcon': '',
        'instagramPhoto': '',
        'telegramTitle': '',
        'telegramIcon': '',
        'telegramPhoto': '',
        'whatsappTitle': '',
        'whatsappIcon': '',
        'whatsappPhoto': '',
        'emailTitle': '',
        'emailIcon': '',
        'emailPhoto': '',
        'site': '',
        'instagram': '',
        'telegram': '',
        'whatsapp': '',
        'email': '',
        'contactUsTitle': '',
        'contactUsIcon': '',
        'contactUsPhoto': '',
        'moduleCarTitle': '',
        'moduleCarIcon': '',
        'moduleCarPhoto': '',
        'moduleNeedTitle': '',
        'moduleNeedIcon': '',
        'moduleNeedPhoto': '',
        'moduleHomeTitle': '',
        'moduleHomeIcon': '',
        'moduleHomePhoto': '',
        'eventTitle': '',
        'eventIcon': '',
        'eventPhoto': '',
    });
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/layout';
        axios({
            method: 'post',
            url: url,
        }).then((response) => {
            if (response.data.status == 1) {
                const responseData = JSON.parse(response.data.data);
                setLayout({
                    'scoreTitle': responseData.scoreTitle,
                    'scoreIcon': responseData.scoreIcon,
                    'scorePhoto': responseData.scorePhoto,
                    'forumTitle': responseData.forumTitle,
                    'forumIcon': responseData.forumIcon,
                    'forumPhoto': responseData.forumPhoto,
                    'commentsTitle': responseData.commentsTitle,
                    'commentsIcon': responseData.commentsIcon,
                    'commentsPhoto': responseData.commentsPhoto,
                    'newsTitle': responseData.newsTitle,
                    'newsIcon': responseData.newsIcon,
                    'newsPhoto': responseData.newsPhoto,
                    'catalogTitle': responseData.catalogTitle,
                    'catalogIcon': responseData.catalogIcon,
                    'catalogPhoto': responseData.catalogPhoto,
                    'siteTitle': responseData.siteTitle,
                    'siteIcon': responseData.siteIcon,
                    'sitePhoto': responseData.sitePhoto,
                    'instagramTitle': responseData.instagramTitle,
                    'instagramIcon': responseData.instagramIcon,
                    'instagramPhoto': responseData.instagramPhoto,
                    'telegramTitle': responseData.telegramTitle,
                    'telegramIcon': responseData.telegramIcon,
                    'telegramPhoto': responseData.telegramPhoto,
                    'whatsappTitle': responseData.whatsappTitle,
                    'whatsappIcon': responseData.whatsappIcon,
                    'whatsappPhoto': responseData.whatsappPhoto,
                    'emailTitle': responseData.emailTitle,
                    'emailIcon': responseData.emailIcon,
                    'emailPhoto': responseData.emailPhoto,
                    'site': responseData.site,
                    'instagram': responseData.instagram,
                    'telegram': responseData.telegram,
                    'whatsapp': responseData.whatsapp,
                    'email': responseData.email,
                    'contactUsTitle': responseData.contactUsTitle,
                    'contactUsIcon': responseData.contactUsIcon,
                    'contactUsPhoto': responseData.contactUsPhoto,
                    'moduleCarTitle': responseData.moduleCarTitle,
                    'moduleCarIcon': responseData.moduleCarIcon,
                    'moduleCarPhoto': responseData.moduleCarPhoto,
                    'moduleNeedTitle': responseData.moduleNeedTitle,
                    'moduleNeedIcon': responseData.moduleNeedIcon,
                    'moduleNeedPhoto': responseData.moduleNeedPhoto,
                    'moduleHomeTitle': responseData.moduleHomeTitle,
                    'moduleHomeIcon': responseData.moduleHomeIcon,
                    'moduleHomePhoto': responseData.moduleHomePhoto,
                    'moduleMoneyTitle': responseData.moduleMoneyTitle,
                    'moduleMoneyIcon': responseData.moduleMoneyIcon,
                    'moduleMoneyPhoto': responseData.moduleMoneyPhoto,
                    'eventTitle': responseData.eventTitle,
                    'eventIcon': responseData.eventIcon,
                    'eventPhoto': responseData.eventPhoto,
                })
            }
        })
    }, []);
    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box">
                            <Header siteData={props.siteData} title={props.siteData.siteName} back='false'/>
                            <div className="visible-yscroll">
                                <div className="row">

                                    <div className="col-12 col-sm-12 col-md-12 m-0 homeSlider">
                                        <div className="card_style ltr">
                                            <Carousel autoPlay={true} infiniteLoop={true} interval={8000} showArrows={false} showIndicators={true}
                                                      showThumbs={false} showStatus={false} transitionTime={1000}>
                                                {slider.map(item =>
                                                    <div>
                                                        <img src={item.photo} alt='slider' title='slider' />
                                                    </div>
                                                )}
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1">
                                            <ul className="row">
                                                {/* درصورت اجازه داشتن باز شوند */}
                                                <li className="item col-4">
                                                    <Link to="/my/scoreHistory" title={layout.scoreTitle} onClick={(e)=>{setPageTitleInStorage(layout.scoreTitle)}}>
                                                        {layout.scoreIcon && (<>
                                                            <i className={layout.scoreIcon}></i>
                                                        </>)}
                                                        {!layout.scoreIcon && (<>
                                                            <span style={{ "backgroundImage" : `url(`+layout.scorePhoto+`)`  }}></span>
                                                        </>)}
                                                        <p>{layout.scoreTitle}</p>
                                                    </Link>
                                                </li>
                                                {props.siteData.commentsAllowShow == 1 && (<>
                                                    <li className="item col-4">
                                                        <Link to="/comments" title={layout.commentsTitle} onClick={(e)=>{setPageTitleInStorage(layout.commentsTitle)}}>
                                                        {layout.commentsIcon && (<>
                                                            <i className={layout.commentsIcon}></i>
                                                        </>)}
                                                        {!layout.commentsIcon && (<>
                                                            <span style={{ "backgroundImage" : `url(`+layout.commentsPhoto+`)`  }}></span>
                                                        </>)}
                                                        <p>{layout.commentsTitle}</p>
                                                    </Link>
                                                    </li>
                                                </>)}
                                                {props.siteData.forumAllowShow == 1 && (<>
                                                    <li className="item col-4">
                                                        <Link to="/forum" title={layout.forumTitle} onClick={(e)=>{setPageTitleInStorage(layout.forumTitle)}}>
                                                        {layout.forumIcon && (<>
                                                            <i className={layout.forumIcon}></i>
                                                        </>)}
                                                        {!layout.forumIcon && (<>
                                                            <span style={{ "backgroundImage" : `url(`+layout.forumPhoto+`)`  }}></span>
                                                        </>)}
                                                        <p>{layout.forumTitle}</p>
                                                    </Link>
                                                    </li>
                                                </>)}
                                                {props.siteData.moduleCarAllowShow == 1 && (<>
                                                    <li className="item col-4">
                                                        <Link to="/moduleCar" title={layout.moduleCarTitle}  onClick={(e)=>{setPageTitleInStorage(layout.moduleCarTitle)}}>
                                                            {layout.moduleCarIcon && (<>
                                                                <i className={layout.moduleCarIcon}></i>
                                                            </>)}
                                                            {!layout.moduleCarIcon && (<>
                                                                <span style={{ "backgroundImage" : `url(`+layout.moduleCarPhoto+`)`  }}></span>
                                                            </>)}
                                                            <p>{layout.moduleCarTitle}</p>
                                                        </Link>
                                                    </li>
                                                </>)}
                                                {props.siteData.moduleNeedAllowShow == 1 && (<>
                                                    <li className="item col-4">
                                                        <Link to="/moduleNeed" title={layout.moduleNeedTitle}  onClick={(e)=>{setPageTitleInStorage(layout.moduleNeedTitle)}}>
                                                            {layout.moduleNeedIcon && (<>
                                                                <i className={layout.moduleNeedIcon}></i>
                                                            </>)}
                                                            {!layout.moduleNeedIcon && (<>
                                                                <span style={{ "backgroundImage" : `url(`+layout.moduleNeedPhoto+`)`  }}></span>
                                                            </>)}
                                                            <p>{layout.moduleNeedTitle}</p>
                                                        </Link>
                                                    </li>
                                                </>)}
                                                {props.siteData.moduleHomeAllowShow == 1 && (<>
                                                    <li className="item col-4">
                                                        <Link to="/moduleHome" title={layout.moduleHomeTitle}  onClick={(e)=>{setPageTitleInStorage(layout.moduleHomeTitle)}}>
                                                            {layout.moduleHomeIcon && (<>
                                                                <i className={layout.moduleHomeIcon}></i>
                                                            </>)}
                                                            {!layout.moduleHomeIcon && (<>
                                                                <span style={{ "backgroundImage" : `url(`+layout.moduleHomePhoto+`)`  }}></span>
                                                            </>)}
                                                            <p>{layout.moduleHomeTitle}</p>
                                                        </Link>
                                                    </li>
                                                </>)}
                                                {props.siteData.moduleMoneyAllowShow == 1 && (<>
                                                    <li className="item col-4">
                                                        <Link to="/moduleMoney" title={layout.moduleMoneyTitle}  onClick={(e)=>{setPageTitleInStorage(layout.moduleMoneyTitle)}}>
                                                            {layout.moduleMoneyIcon && (<>
                                                                <i className={layout.moduleMoneyIcon}></i>
                                                            </>)}
                                                            {!layout.moduleMoneyIcon && (<>
                                                                <span style={{ "backgroundImage" : `url(`+layout.moduleMoneyPhoto+`)`  }}></span>
                                                            </>)}
                                                            <p>{layout.moduleMoneyTitle}</p>
                                                        </Link>
                                                    </li>
                                                </>)}
                                                {props.siteData.newsAllowShow == 1 && (<>
                                                    <li className="item col-4">
                                                        <Link to="/news" title={layout.newsTitle}  onClick={(e)=>{setPageTitleInStorage(layout.newsTitle)}}>
                                                            {layout.newsIcon && (<>
                                                                <i className={layout.newsIcon}></i>
                                                            </>)}
                                                            {!layout.newsIcon && (<>
                                                                <span style={{ "backgroundImage" : `url(`+layout.newsPhoto+`)`  }}></span>
                                                            </>)}
                                                            <p>{layout.newsTitle}</p>
                                                        </Link>
                                                    </li>
                                                </>)}
                                                {props.siteData.eventAllowShow == 1 && (<>
                                                    <li className="item col-4">
                                                        <Link to="/event" title={layout.eventTitle}  onClick={(e)=>{setPageTitleInStorage(layout.eventTitle)}}>
                                                            {layout.eventIcon && (<>
                                                                <i className={layout.eventIcon}></i>
                                                            </>)}
                                                            {!layout.eventIcon && (<>
                                                                <span style={{ "backgroundImage" : `url(`+layout.eventPhoto+`)`  }}></span>
                                                            </>)}
                                                            <p>{layout.eventTitle}</p>
                                                        </Link>
                                                    </li>
                                                </>)}
                                                <li className="item col-4">
                                                    <Link to="/contactUs" title={layout.contactUsTitle} onClick={(e)=>{setPageTitleInStorage(layout.contactUsTitle)}}>
                                                        {layout.contactUsIcon && (<>
                                                            <i className={layout.contactUsIcon}></i>
                                                        </>)}
                                                        {!layout.contactUsIcon && (<>
                                                            <span style={{ "backgroundImage" : `url(`+layout.contactUsPhoto+`)`  }}></span>
                                                        </>)}
                                                        <p>{layout.contactUsTitle}</p>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <TaskBar siteData={props.siteData}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Home;

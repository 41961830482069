import React, { Component, useState, useEffect, memo } from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import useScript from "../hook/useScript";
import { useCookies } from "react-cookie";
import Header from "../layouts/header";
import TaskBar from "../layouts/taskBar";
import Notification from "../components/notification";
import Loader from "../components/loader";

// صفحه تماس با ما
const ContactUs = (props) => {
    const history = useHistory();

    const pageTitleInStorage = localStorage.getItem('pageTitleInStorage');
    {document.title = 'ارتباط با ما'}
    const [cookies, setCookie] = useCookies();

    // ###############################
    // تنظیمات قالب
    const [layout, setLayout] = useState({
        'siteTitle': '',
        'siteIcon': '',
        'sitePhoto': '',
        'instagramTitle': '',
        'instagramIcon': '',
        'instagramPhoto': '',
        'telegramTitle': '',
        'telegramIcon': '',
        'telegramPhoto': '',
        'whatsappTitle': '',
        'whatsappIcon': '',
        'whatsappPhoto': '',
        'emailTitle': '',
        'emailIcon': '',
        'emailPhoto': '',
        'site': '',
        'instagram': '',
        'telegram': '',
        'whatsapp': '',
        'email': '',
    });
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/layout';
        axios({
            method: 'post',
            url: url,
        }).then((response) => {
            if (response.data.status == 1) {
                const responseData = JSON.parse(response.data.data);
                setLayout({
                    'siteTitle': responseData.siteTitle,
                    'siteIcon': responseData.siteIcon,
                    'sitePhoto': responseData.sitePhoto,
                    'instagramTitle': responseData.instagramTitle,
                    'instagramIcon': responseData.instagramIcon,
                    'instagramPhoto': responseData.instagramPhoto,
                    'telegramTitle': responseData.telegramTitle,
                    'telegramIcon': responseData.telegramIcon,
                    'telegramPhoto': responseData.telegramPhoto,
                    'whatsappTitle': responseData.whatsappTitle,
                    'whatsappIcon': responseData.whatsappIcon,
                    'whatsappPhoto': responseData.whatsappPhoto,
                    'emailTitle': responseData.emailTitle,
                    'emailIcon': responseData.emailIcon,
                    'emailPhoto': responseData.emailPhoto,
                    'site': responseData.site,
                    'instagram': responseData.instagram,
                    'telegram': responseData.telegram,
                    'whatsapp': responseData.whatsapp,
                    'email': responseData.email,
                });

                Loader('remove');
            }
        })
    }, []);


    //#####################
    //  درخواست اطلاعات از سرور
    const [data, setData] = useState([]);
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/pages/contactUs';
        axios({
            method: 'post',
            url: url,
        }).then(async (response) => {
            if (response.data.status === 1) {
                await setData(response.data.data);
                Loader('remove');
            }
        })
    }, []);



    return (
        <>
            {/* کدهای اچ تی ام ال */}
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title='ارتباط با ما' back='true' help="helpContactUs" />
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">

                                            <div className="row contactUsContent">
                                                <div dangerouslySetInnerHTML={{ __html: data }} />
                                            </div>

                                            <div className="contactUsItems row">
                                                <ul>
                                                    <li>
                                                        <a href={layout.site} title={layout.siteTitle} className="btn btn-1 hover2 site">
                                                            <i className={layout.siteIcon}></i>
                                                        </a>
                                                    </li>
                                                    {props.siteData.socialMediaAllowShow == 1 && (<>
                                                        <li>
                                                            <a href={layout.site} title={layout.instagramTitle} className="btn btn-1 hover2 instagram">
                                                                <i className={layout.instagramIcon}></i>
                                                            </a>
                                                        </li>
                                                    </>)}
                                                    {props.siteData.socialMediaAllowShow == 1 && (<>
                                                        <li>
                                                            <a href={layout.site} title={layout.telegramTitle} className="btn btn-1 hover2 telegram">
                                                                <i className={layout.telegramIcon}></i>
                                                            </a>
                                                        </li>
                                                    </>)}
                                                    {props.siteData.socialMediaAllowShow == 1 && (<>
                                                        <li>
                                                            <a href={layout.site} title={layout.whatsappTitle} className="btn btn-1 hover2 whatsapp">
                                                                <i className={layout.whatsappIcon}></i>
                                                            </a>
                                                        </li>
                                                    </>)}
                                                    {props.siteData.socialMediaAllowShow == 1 && (<>
                                                        <li>
                                                            <a href={"mailto:"+layout.site} title={layout.emailTitle} className="btn btn-1 hover2 email">
                                                                <i className={layout.emailIcon}></i>
                                                            </a>
                                                        </li>
                                                    </>)}
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactUs;
import React, {useRef, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useCookies} from 'react-cookie'

import axios from 'axios';
import {BrowserRouter as Router, Switch, Route, Link, useParams, useLocation} from "react-router-dom";
import './Bootstrap.css';
import Sidebar from './layouts/sidebar';
import Footer from './layouts/footer';
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import NotFound from './pages/404';
import Home from './pages/home';
import Comments from './pages/comments';
import AboutUs from './pages/aboutUs';
import Faq from './pages/faq';
import Forum from './pages/forum';
import Profile from './pages/my/profile';
import ChangePass from './pages/my/changePass';
import ScoreHistory from './pages/my/scoreHistory';
import News from './pages/news/all';
import NewsDetail from './pages/news/detail';
import ContactUs from './pages/contactUs';

import ModuleCarMain from './pages/moduleCar/main';
import ModuleCarDetail from './pages/moduleCar/detail';

import ModuleCarSales from './pages/moduleCar/sales';
import ModuleCarSaleAdd from "./pages/moduleCar/saleAdd";
import ModulesAuction from './pages/moduleCar/auctions';
import ModuleCarAuctionAdd from "./pages/moduleCar/auctionAdd";
import ModuleCarRents from './pages/moduleCar/rents';
import ModuleCarRentAdd from "./pages/moduleCar/rentAdd";

import ModuleCarNeeds from './pages/moduleNeed/needs';
import ModuleCarNeedAdd from "./pages/moduleNeed/needAdd";
import ModuleCarNeedDetail from './pages/moduleNeed/needDetail';

import ModuleHomeNeeds from './pages/moduleHome/all';
import ModuleHomeNeedAdd from "./pages/moduleHome/add";
import ModuleHomeNeedDetail from './pages/moduleHome/detail';

import ModuleMoneyMy from './pages/moduleMoney/my';
import ModuleMoneyAdd from "./pages/moduleMoney/add";

import Event from './pages/event/all';
import EventDetail from './pages/event/detail';
import EventRegister from './pages/event/register';
import EventMy from './pages/event/my';

function Bootstrap() {
    const [cookies, setCookie] = useCookies();

    // ###############################
    // ###############################
    // ###############################
    // ###########n      تنظیمات باشگاه
    const [siteData, setSiteData] = useState([
        'logo', // لوگو
        'siteName', // نام باشگاه
        'userAvatar', // آواتار کاربر
        'mainBackground', // پس زمینه اصلی
        'menuBackground', // پس زمینه منو
        'appBackground',// گیف کارت
        'forumAllowShow',// اجازه نمایش تالار
        'commentsAllowShow',// اجازه نمایش پشتیبانی
        'newsAllowShow',// اجازه نمایش خبر
        'sliderAllowShow',// اجازه نمایش خبر
        'catalogAllowShow',// اجازه نمایش کاتالوگ
        'socialMediaAllowShow',// اجازه نمایش شبکه هاب اجتماعی
        'moduleCarAllowShow',// اجازه نمایش شبکه هاب اجتماعی
        'moduleNeedAllowShow',// اجازه نمایش شبکه هاب اجتماعی
        'moduleHomeAllowShow',// اجازه نمایش شبکه هاب اجتماعی
        'moduleMoneyAllowShow',// اجازه نمایش شبکه هاب اجتماعی
    ]);
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/options';
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            // بررسی وضعیت برگشت داده
            // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
            if (response.data.status == 1) {
                const responseData = JSON.parse(response.data.data);
                setSiteData({
                    'logo': responseData.logo,
                    'siteName': responseData.siteName,
                    'userAvatar': responseData.userAvatar,
                    'mainBackground': responseData.mainBackgroundPhoto,
                    'menuBackground': responseData.menuBackgroundPhoto,
                    'appBackground': responseData.appBackgroundPhoto,
                    'loginBackgroundPhoto': responseData.loginBackgroundPhoto,
                    'gifCard': responseData.gifCard,
                    'forumAllowShow': responseData.forumAllowShow,
                    'commentsAllowShow': responseData.commentsAllowShow,
                    'newsAllowShow': responseData.newsAllowShow,
                    'sliderAllowShow': responseData.sliderAllowShow,
                    'catalogAllowShow': responseData.catalogAllowShow,
                    'socialMediaAllowShow': responseData.socialMediaAllowShow,
                    'moduleCarAllowShow': responseData.moduleCarAllowShow,
                    'moduleNeedAllowShow': responseData.moduleNeedAllowShow,
                    'moduleHomeAllowShow': responseData.moduleHomeAllowShow,
                    'moduleMoneyAllowShow': responseData.moduleMoneyAllowShow,
                    'eventAllowShow': responseData.eventAllowShow,
                })
            }
        })
    }, []);


    // ###############################
    // ###########m       اطلاعات کاربر
    const [userData, setUserData] = useState({
        'firstName': '',// نام
        'lastName': '',// فامیل
        'userName': '',// یوزرنیم
        'avatar': '',// تصویر
        'score': '',// امتیاز
    });
    useEffect(() => {
        if (cookies.tokenForAccess) {
            const url = process.env.REACT_APP_URL + '/api/v1/user/userProfile';
            let data = new FormData();
            data.append("token", cookies.tokenForAccess);
            axios({
                method: 'post', url: url,
                data,
                headers: {
                    Authorization: 'Bearer ' + cookies.tokenForAccess,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    // بررسی وضعیت برگشت داده
                    // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                    if (response.data.status == 1) {
                        setUserData({
                            'firstName': response.data.data.userInfo.firstName ? response.data.data.userInfo.firstName : '',
                            'lastName': response.data.data.userInfo.lastName ? response.data.data.userInfo.lastName : '',
                            'userName': response.data.data.userInfo.username ? response.data.data.userInfo.username : '',
                            'avatar': response.data.data.userInfo.photo ? response.data.data.userInfo.photo : '',
                            'score': response.data.data.userInfo.score ? response.data.data.userInfo.score : '',
                        })
                    }
                })
        }
    }, []);


    return (
        <>
            <Router>
                {cookies.tokenForAccess && (
                    <>
                        <Sidebar siteData={siteData}/>
                    </>
                )}
                <Switch>
                    <Route exact render={(props) => <ModuleCarMain siteData={siteData} {...props} />} path="/moduleCar"/>
                    <Route exact render={(props) => <ModuleCarDetail siteData={siteData} {...props} />} path="/moduleCar/detail/:id"/>
                    <Route exact render={(props) => <ModuleCarSales siteData={siteData} {...props} />} path="/moduleCar/sales"/>
                    <Route exact render={(props) => <ModuleCarSaleAdd siteData={siteData} {...props} />} path="/moduleCar/sale/add"/>
                    <Route exact render={(props) => <ModulesAuction siteData={siteData} {...props} />} path="/moduleCar/auctions"/>
                    <Route exact render={(props) => <ModuleCarAuctionAdd siteData={siteData} {...props} />} path="/moduleCar/auction/add"/>
                    <Route exact render={(props) => <ModuleCarRents siteData={siteData} {...props} />} path="/moduleCar/rents"/>
                    <Route exact render={(props) => <ModuleCarRentAdd siteData={siteData} {...props} />} path="/moduleCar/rent/add"/>

                    <Route exact render={(props) => <ModuleMoneyMy siteData={siteData} {...props} />} path="/moduleMoney"/>
                    <Route exact render={(props) => <ModuleMoneyAdd siteData={siteData} {...props} />} path="/moduleMoney/add"/>

                    <Route exact render={(props) => <ModuleCarNeeds siteData={siteData} {...props} />} path="/moduleNeed"/>
                    <Route exact render={(props) => <ModuleCarNeedAdd siteData={siteData} {...props} />} path="/moduleNeed/add"/>
                    <Route exact render={(props) => <ModuleCarNeedDetail siteData={siteData} {...props} />} path="/moduleNeed/detail/:id"/>

                    <Route exact render={(props) => <ModuleHomeNeeds siteData={siteData} {...props} />} path="/moduleHome"/>
                    <Route exact render={(props) => <ModuleHomeNeedAdd siteData={siteData} {...props} />} path="/moduleHome/add"/>
                    <Route exact render={(props) => <ModuleHomeNeedDetail siteData={siteData} {...props} />} path="/moduleHome/detail/:id"/>

                    <Route exact render={(props) => <Login siteData={siteData} {...props} />} path="/login" />
                    <Route exact render={(props) => <Register siteData={siteData} {...props} />} path="/register" />
                    <Route exact render={(props) => <Home siteData={siteData} {...props} />} path="/"/>
                    <Route exact render={(props) => <ContactUs siteData={siteData} {...props} />} path="/contactUs"/>
                    <Route exact render={(props) => <AboutUs siteData={siteData} {...props} />} path="/aboutUs"/>
                    <Route exact render={(props) => <Faq siteData={siteData} {...props} />} path="/faq"/>
                    <Route exact render={(props) => <ScoreHistory siteData={siteData} {...props} />} path="/my/scoreHistory"/>
                    <Route exact render={(props) => <Profile siteData={siteData} {...props} />} path="/my/profile"/>
                    <Route exact render={(props) => <ChangePass siteData={siteData} {...props} />} path="/my/changePass"/>
                    <Route exact render={(props) => <Forum siteData={siteData} {...props} />} path="/forum"/>
                    <Route exact render={(props) => <Comments siteData={siteData} {...props} />} path="/comments"/>

                    <Route exact render={(props) => <News siteData={siteData} {...props} />} path="/news"/>
                    <Route exact render={(props) => <NewsDetail siteData={siteData} {...props} />} path="/news/detail/:id"/>
                    <Route exact render={(props) => <Event siteData={siteData} {...props} />} path="/event"/>
                    <Route exact render={(props) => <EventDetail siteData={siteData} {...props} />} path="/event/detail/:id"/>
                    <Route exact render={(props) => <EventRegister siteData={siteData} {...props} />} path="/event/register/:id"/>
                    <Route exact render={(props) => <EventMy siteData={siteData} {...props} />} path="/event/my"/>

                    <Route component={NotFound}/>
                </Switch>
            </Router>
        </>
    );
}
export default Bootstrap;
import React, { Component, useState, useEffect, memo } from 'react';
import axios from 'axios';
import $ from 'jquery';
import useScript from './../hook/useScript';
import Notification from './../components/notification';
import { useCookies } from "react-cookie";
import Sidebar from './../layouts/sidebar';
import TaskBar from './../layouts/taskBar';
import Header from '../layouts/header';
import Loader from '../components/loader';


// صفحه تالار گفتگو
const Forum = (props) => {
    const subDomain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.')[0];

    { document.title = 'تالار گفتگو' }

    const [cookies, setCookie] = useCookies();

    // ###############################
    // ###########       Set Data
    const [allForums, setAllForums] = useState([]);
    const [comment, setComment] = useState('');

    // ###############################
    // لیست تمام پیام ها
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/forum/all';
        Loader('show');
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setAllForums(response.data.data);
                }
                else {
                    // نمایش پیغام سرور
                    Notification('error', response.data.data);
                }
                Loader('remove');
            })
    }, []);


    // ###############################
    // ذخیره پیام
    const registerForum = async () => {
        let data = new FormData();
        data.append('comment', comment);
        if (comment === '') {
            Notification('error', "وارد کردن نظر الزامی می باشد");
        } else {
            const urlStore = process.env.REACT_APP_URL + '/api/v1/forum/store';
            Loader('show');
            axios({
                method: 'post', url: urlStore,
                data,
                headers: {
                    Authorization: 'Bearer ' + cookies.tokenForAccess,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res => {
                    // بررسی وضعیت برگشت داده
                    // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                    if (res.data.status == 1) {
                        document.getElementById('commentElement').value = '';
                        setComment('');
                        let newForum = $('li.chatItemExample').first();
                        newForum = newForum.clone();
                        newForum.removeClass('chatItemExample disnone');
                        newForum.find('.comment span').text(res.data.data[0].date);
                        newForum.find('.comment p').text(res.data.data[0].comment);
                        newForum.find('.user p').text(res.data.data[0].firstName + ' ' + res.data.data[0].lastName);
                        $("#chatBox ul").append(newForum);
                        Notification('success', 'نظر با موفقیت ثبت گردید');
                        Loader('remove');
                    }
                    else {
                        // نمایش پیغام سرور
                        Notification('error', res.data.data);
                    }
                }))
                .catch(error => {
                    // بروز هرگونه خطا از سمت سرور
                    if (error.response) {
                        Notification('error', error.response);
                    }
                    Loader('remove');
                })
        }
    }

    return (
        <>
            {/* کد های اچ تی ام ال */}
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box">

                            <Header siteData={props.siteData} title='تالار گفتگو' back='true' help="helpFurom" />


                            <div className="visible-yscroll">
                                <div className="row m-0">
                                    <div className="col-12 col-sm-12 col-md-12 m-0 p-0">
                                        <div id="chatBox">
                                            <ul>
                                                {
                                                    allForums.map((item, index) => {
                                                        return (

                                                            <li key={"msg_" + index}>
                                                                <div className={item.user_type == 1 ? 'left' : 'right'}>
                                                                    <div className="user">
                                                                        <i className='fa fa-user-circle'></i>
                                                                        <p>{item.firstName} {item.lastName}</p>
                                                                    </div>

                                                                    <div className='comment'>
                                                                        <p>{item.comment}</p>
                                                                        <span className={item.user_type == 1 ? 'ml-auto' : 'mr-auto'}>{item.date}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row newCmmentSection">
                                <input type="text" className="form-control" id="commentElement" onChange={(value) => {
                                    setComment(value.currentTarget.value)
                                }} placeholder="متن خود را وارد کنید ... " />
                                <button className='btn btn-info p-0' onClick={registerForum}><i className="fas fa-location-arrow"></i></button>
                            </div>


                        </div>

                        <TaskBar />


                    </div>
                </div>
            </div>


            <li className="chatItemExample disnone">
                <div className='right'>
                    <div className="user">
                        <i className='fa fa-user-circle'></i>
                        <p></p>
                    </div>

                    <div className='comment'>
                        <p></p>
                        <span className='ml-auto'></span>
                    </div>
                </div>
            </li>


        </>
    )
}
export default Forum;
import React, { Component, useState, useEffect, memo } from 'react';
import $ from 'jquery';
import axios from 'axios';
import reactDom from 'react-dom';

import Notification from './../../components/notification';
import { useCookies } from "react-cookie";
import { Link, useHistory } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import Loader from '../../components/loader';
import Field from '../../components/field';

// صفحه ثبت نام
const Register = (props) => {
    const subDomain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.')[0];

    { document.title = 'ثبت نام در باشگاه' }

    //  برای جابجای بین صفحات
    const history = useHistory();
    const [cookies, setCookie] = useCookies();

    // بررسی اینکه قبلا احرازهویت صورت گرفته یا نه
    if (cookies.tokenForAccess)
        history.push("/");

    Loader('remove');

    const [slider, setSlider] = useState([]);
    const [countryCodes, setCountryCodes] = useState([]);
    const [faq, setPageFaq] = useState('');

    // ###############################
    //  درخواست اسلایدر
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/slider';
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setSlider(response.data.data);
                }
            })
    }, []);


    // ###############################
    // درخواست راهنما
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/pages/faq';
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    setPageFaq(response.data.data);
                }
            })
    }, []);

    // ###############################
    // درخواست پیش شماره کشور ها
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/user/countryCodes';
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.data.status === 1) {
                    setCountryCodes(response.data.data);
                }
            })
    }, []);


    // ###############################
    // ست کردن تنطیمات سایت
    const [siteData, setSiteData] = useState({
        'logo': '',
        'siteName': '',
        'userAvatar': '',
        'mainBackground': '',
        'menuBackground': '',
        'appBackground': '',
        'loginBackgroundPhoto': '',
    });
    useEffect(() => {
        if (props.siteData) {
            setSiteData({
                'logo': props.siteData.logo,
                'siteName': props.siteData.siteName,
                'userAvatar': props.siteData.userAvatar,
                'mainBackground': props.siteData.mainBackground,
                'menuBackground': props.siteData.menuBackground,
                'appBackground': props.siteData.appBackground,
                'loginBackgroundPhoto': props.siteData.loginBackgroundPhoto,
            })
        }
    }, [props.siteData])



    // ###############################
    // ###########       Set Data
    const [userCookie, setUserCookie] = useCookies(['id', 'mobile', 'tokenForAccess', 'firstName', 'lastName']);
    const [code, setCode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [rePaasword, setRePassword] = useState('');

    // ###############################
    // درخواست ثبت نام
    const registerAxios = async () => {
        let data = new FormData();
        data.append('firstName', firstName);
        data.append('lastName', lastName);
        data.append('mobile', mobile);
        data.append('password', password);
        data.append('rePaasword', rePaasword);
        data.append('countryCode', countryCode);

        Loader('show');

        const url = process.env.REACT_APP_URL + '/api/v1/user/register';
        axios({
            method: 'post', url: url,
            data,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res => {
                Loader('remove');
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (res.data.status == 1) {
                    Notification('success', "ثبت نام شما با موفقیت انجام شد. لطفا کد تایید هویت را وارد نمایید");
                    window.$('#verifyModal').modal('show');
                } else {
                    // نمایش لست خطاها
                    const resData = res.data.data;
                    if (Array.isArray(resData)) {
                        res.data.data.forEach(function (currentValue, index, arr) {
                            Notification('error', currentValue);
                        })
                    } else
                        Notification('error', resData);
                }
            }))
            .catch(error => {
                //  بروز هرگئنه خطایی از سمت سرور
                const resData = error.response.data.errors;
                if(typeof resData === 'object') {
                    for (const [key, value] of Object.entries(resData)) {
                        Notification('error', value);
                    }
                }else
                    Notification('error', 'خطای پیش بینی نشده . لطفا کمی بعد مجددا امتحان فرمائید');

                Loader('remove');
            })
        // }
    }


    // ###############################
    // درخواست وریفای کاربر
    const verifyAxios = async () => {
        let data = new FormData();
        data.append('code', code);
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/user/verify';
        axios({
            method: 'post', url: url,
            data,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (res.data.status == 1) {
                    // تنظیم کردن اطلاعات کاربر در کوکی مرورگر
                    let today = new Date(new Date);
                    today.setDate(today.getDate() + 7)
                    setUserCookie('id', res.data.data.id, { path: '/', expires: today })
                    setUserCookie('tokenForAccess', res.data.data.token, { path: '/', expires: today })
                    setUserCookie('firstName', res.data.data.firstName, { path: '/', expires: today })
                    setUserCookie('lastName', res.data.data.lastName, { path: '/', expires: today })
                    history.push("/");
                } else {
                    const resData = res.data.data;
                    if (Array.isArray(resData)) {
                        res.data.data.forEach(function (currentValue, index, arr) {
                            Notification('error', currentValue);
                        })
                    } else
                        Notification('error', resData);
                }
                Loader('remove');
            }))
            .catch(error => {
                // بروز هرگونه خطایی از سمت سرور
                if (error.response) {
                    Notification('error', 'خطای پیش بینی نشده . لطفا کمی بعد مجددا امتحان فرمائید');
                    Loader('remove');
                }
            })
    }

    return (
        <>
            <main>
                <div className="container-fluid">
                    <div className="row pt-4  mt-5">
                        <div className="col-12 col-md-6">
                            <div className="col-xs-12 col-lg-7 mx-auto form-1">
                                <div className="list-style2 money-transfer">
                                    <div className="item">
                                        <p className="alert-white">
                                            <span id="money1"></span> ثبت نام
                                            <i className="fa fa-question-circle" data-toggle="modal" data-target="#faqModal"></i>
                                        </p>
                                    </div>
                                    <div className="item">
                                        <div className="form-group required">
                                            <div className="form-icon type2"><i className="fa fa-edit"></i></div>
                                            <input type="text" className="form-control ph2" placeholder="نام" id='firstName'
                                                onChange={(value) => {
                                                    setFirstName(value.currentTarget.value)
                                                }} value={firstName} />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="form-group required">
                                            <div className="form-icon type2"><i className="fa fa-edit"></i></div>
                                            <input type="text" className="form-control ph2" placeholder="نام خانوادگی" id='lastName'
                                                onChange={(value) => {
                                                    setLastName(value.currentTarget.value)
                                                }} value={lastName} />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="form-group required">
                                            <div className="form-icon type2"><i className="fa fa-phone"></i></div>
                                            <Field dir='rtl' placeholder='موبایل' className="form-control ph2" type='number' id="mobile" change={(value) => {
                                                setMobile(value)
                                            }} value={mobile} format='###########' />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="form-group required">
                                            <div className="form-icon type2"><i className="fa fa-code"></i></div>
                                            <select className="form-control ph2" id="countryCode" defaultValue={String(countryCode)}
                                                    onChange={(value) => {
                                                        setCountryCode(value.currentTarget.value);
                                                    }}>
                                                <option value={0} selected={countryCode == 0}>انتخاب کد کشور</option>
                                                {
                                                    countryCodes.map((item, index) => {
                                                        return (
                                                            <>
                                                                <option value={item.code} selected={countryCode == item.code}>{item.country}</option>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="form-group required">
                                            <div className="form-icon type2"><i className="fa fa-lock"></i></div>
                                            <input type="password" className="form-control ph2" placeholder="رمز عبور" value={password}
                                                onChange={(value) => {
                                                    setPassword(value.currentTarget.value)
                                                }} />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="form-group required">
                                            <div className="form-icon type2"><i className="fa fa-lock"></i></div>
                                            <input type="password" className="form-control ph2" placeholder="تایید رمز عبور" value={rePaasword}
                                                onChange={(value) => {
                                                    setRePassword(value.currentTarget.value)
                                                }} />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <Link to="/login" className="link1 btn btn-1 float-left" style={{ minWidth: '60px' }}>ورود</Link>
                                        <button type="button" className="btn btn-1 ml-auto" onClick={registerAxios}>ثبت نام</button>
                                    </div>
                                </div>

                                {/* مودال سوالات متداول */}
                                <div className="modal fade" id="faqModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                                    aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <button type="button" className="close" data-dismiss="modal">
                                                <span aria-hidden="true">×</span>
                                                <span className="sr-only">Close</span>
                                            </button>
                                            <div className="modal-body">
                                                <div dangerouslySetInnerHTML={{ __html: faq }} />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-1 hover2" data-dismiss="modal" style={{ minWidth: '60px' }}>بستن</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 hidden-sm-down ltr">
                            <Carousel autoPlay={true} infiniteLoop={true} interval={8000} showArrows={false} showIndicators={true}
                                showThumbs={false} showStatus={false} transitionTime={1000}>
                                {slider.map(item =>
                                    <div>
                                        <img src={item.photo} alt='slider' title='slider' />
                                    </div>
                                )}
                            </Carousel>
                        </div>
                    </div>
                </div>
                <footer className="hidden-sm-down" style={{ backgroundImage: `url(${siteData.loginBackgroundPhoto})`}}></footer>
            </main>

            {/* مودال وریفای */}
            <div className="modal fade" id="verifyModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content px-5">
                        <h2 className="modal-title">احرازهویت</h2>
                        <button type="button" className="close" data-dismiss="modal">
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close</span>
                        </button>
                        <div className=" col-md-12 modal-body">

                            <div className="form-group forget-password required">
                                <label> کد: </label>
                                <input className="form-control effect-7 mobile" type="text" id="code" value={code}
                                       onChange={(value) => {
                                           setCode(value.currentTarget.value)
                                       }} />
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" onClick={verifyAxios} className="btn btn-1 ml-2 hover1" data-dismiss="modal">ارسال</button>
                            <button type="button" id="changeBtn" className="btn btn-default" data-dismiss="modal">انصراف </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default Register;
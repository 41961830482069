import React, {Component, useState, useEffect, memo} from 'react';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import Header from "../layouts/header";
import TaskBar from "../layouts/taskBar";

const NotFound = (props) => {

    { document.title = '404' }

    return (
        <>


            <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box">

                            <Header siteData={props.siteData} title='404' back='true'/>

                            <div className="visible-yscroll">

                                <div style={{     backgroundColor:"#fff" , margin: '100px 0 0 0' ,  padding: '15px' , textAlign: 'center' ,
                                  borderRadius:' 5px' , lineHeight: 2}}>


                                    <h1>404</h1>

                                    <h3>صفحه موردنظر یافت نشد</h3>

                                    <Link to="/">صفحه اصلی</Link>

                                </div>      

                            </div>

                        </div>

                        <TaskBar/>

                    </div>
                </div>
            </div>

        </>
    )
}
export default NotFound;

import Lobibox from "./../plugin/lobibox.min";

const Notification = (type, msg) => {
    var icon = '';
    switch (type) {
        case 'success':
            icon = 'bx bx-check-circle';
            break;
        case 'error':
            icon = 'bx bx-x-circle';
            break;
        case 'warning':
            icon = 'bx bx-error';
            break;
        case 'info':
            icon = 'bx bx-info-circle';
            break;
    }
    Lobibox.notify(type, {
        icon: icon,
        pauseDelayOnHover: true,
        continueDelayOnInactiveTab: false,
        size: 'mini',
        position: 'top center',
        msg: msg
    });
};
export default Notification;

import React, {Component, useState, useEffect, memo} from 'react';
import $ from 'jquery';
import axios from 'axios';
import reactDom from 'react-dom';
import useScript from './../../hook/useScript';

import Notification from './../../components/notification';
import {useCookies} from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import Loader from '../../components/loader';


// تغییر رمز عبور'
const ChangePass = (props) => {
    {document.title = 'تغییر رمز عبور'}
    const [cookies, setCookie] = useCookies();

    //   Set Data
    const [newPass, setNewPass] = useState('');
    const [reNewPass, setReNewPass] = useState('');

    //######################
    // درخواست تغییر رمز عبور
    const changePassAction = async () => {
        if (newPass == '') {
            Notification('error', "وارد کردن رمز عبور جدید الزامیست");
        } else if (reNewPass == '') {
            Notification('error', "وارد کردن تکرار رمزعبور جدید الزامیست");
        } else if (reNewPass != newPass) {
            Notification('error', "رمز عبور جدید با تکرار آن مطابقت ندارد");
        } else {
            let data = new FormData();

            data.append('password', newPass);
            data.append('password_confirmation', reNewPass);
            Loader('show');

            const url = process.env.REACT_APP_URL + '/api/v1/user/changePass';
            axios({
                method: 'post', url: url,
                data,
                headers: {
                    Authorization: 'Bearer ' + cookies.tokenForAccess,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res => {
                    // بررسی وضعیت برگشت داده
                    // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                    if (res.data.status == 1) {
                        Notification('success', res.data.data);
                    }
                    Loader('remove');
                }))
                .catch(error => {
                    if (error.response) {
                        Notification('error', "خطای پیش بینی نشده! لطفا دوباره امتحان کنید ");
                    }
                    Loader('remove');
                })
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title='تغییر رمز عبور' back='true'/>
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            <br/><br/><br/>
                                            <section className="form-2">
                                                <div className="form-group">
                                                    <label htmlFor="newPass" className="form-label">رمز عبور جدید </label>
                                                    <input type="password" className="form-control" id='newPass' onChange={(value) => {
                                                        setNewPass(value.currentTarget.value)
                                                    }} value={newPass}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="reNewPass" className="form-label">تکرار رمز عبور</label>
                                                    <input type="password" className="form-control" id='reNewPass' onChange={(value) => {
                                                        setReNewPass(value.currentTarget.value)
                                                    }} value={reNewPass}/>
                                                </div>
                                                <div>
                                                    <button className="btn btn-outline-info btn-1 hover2" onClick={changePassAction}>ذخیره</button>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar/>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ChangePass;
import React, { Component, useState, useEffect, memo } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    Link,
    useParams
} from "react-router-dom";


import axios from 'axios';
import Notification from './../../components/notification';
import { useCookies } from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import { Carousel } from 'react-responsive-carousel';
import Loader from '../../components/loader';


// صفحه جزئیات آگهی
const FormDetail = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    // بررسی اینکه آیا اجازه نمایش این صفحه از پنل مدیریت داده شده است یا نه
    if (props.siteData.moduleCarAllowShow != 1)
        history.push("/");


    Loader('show');
    {document.title = 'جزئیات آگهی'}


    // ###############################
    // ###########       Set Data
    const [cookies, setCookie] = useCookies(); // کوکی
    const [id, setId] = useState(useParams().id); // شناسه جدول
    const [title, setTitle] = useState(''); // عنوان
    const [date, setDate] = useState(''); // تاریخ
    const [brand, setBrand ] = useState('');
    const [tip, setTip ] = useState('');
    const [model, setModel ] = useState('');
    const [fuel, setFuel ] = useState('');
    const [gearbox, setGearbox ] = useState('');
    const [color, setColor ] = useState('');
    const [work, setWork ] = useState('');
    const [body_status, setBody_status ] = useState('');
    const [engine_status, setEngine_status ] = useState('');
    const [gearbox_status, setGearbox_status ] = useState('');
    const [third_party_insurance, setThird_party_insurance ] = useState('');
    const [body_insurance, setBody_insurance ] = useState('');
    const [price, setPrice ] = useState('');
    const [startPrice, setStartPrice ] = useState('');
    const [payment_method, setPayment_method ] = useState('');
    const [delivery_time, setDelivery_time ] = useState('');
    const [testingTime, setTestingTime] = useState('');
    const [change, setChange] = useState('');
    const [deductions_0, setDeductions_0] = useState([]);
    const [deductions_1, setDeductions_1] = useState([]);
    const [video, setVideo] = useState(''); // ویدیو
    const [photos, setPhoto] = useState([]);//  تصاویر
    const [description, setDescription] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [startDate, setStartDate] = useState('');// تاریخ شروع
    const [endDate, setEndDate] = useState('');// تاریخ پایان

    const [dayPrice, setDayPrice] = useState('');
    const [weekPrice, setWeekPrice] = useState('');
    const [monthPrice, setMonthPrice] = useState('');
    const [delivery_type, setDelivery_type] = useState('');

    // ###############################
    // ###########m    ارسال درخواست به سرور برای دریافت آگهی مورد نظر
    useEffect(() => {
        const url1 = process.env.REACT_APP_URL + '/api/v1/moduleCar/car/detail';
        axios({
            method: 'post',
            data: { 'id': id },
            url: url1,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
            }
        })
            // بازگشت داده از سرور
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    // ست کردن داده های برگشتی
                    // const responseData = JSON.parse(response.data.data);
                    const responseData = response.data.data;
                    await setTitle(responseData.title)
                    await setDate(responseData.date)
                    await setVideo(responseData.video)
                    await setPhoto(responseData.photos)
                    await setDeductions_0(responseData.deductions_0)
                    await setDeductions_1(responseData.deductions_1)
                    await setChange(responseData.change)
                    await setBrand(responseData.brand)
                    await setTip(responseData.tip)
                    await setModel(responseData.model)
                    await setFuel(responseData.fuel)
                    await setGearbox(responseData.gearbox)
                    await setColor(responseData.color)
                    await setWork(responseData.work)
                    await setBody_status(responseData.body_status)
                    await setEngine_status(responseData.engine_status)
                    await setGearbox_status(responseData.gearbox_status)
                    await setTestingTime(responseData.third_party_insurance)
                    await setBody_insurance(responseData.body_insurance)
                    await setPrice(responseData.price)
                    await setPayment_method(responseData.payment_method)
                    await setDelivery_time(responseData.delivery_time)
                    await setTestingTime(responseData.testingTime)
                    await setDescription(responseData.description)
                    await setPhone(responseData.phone)
                    await setAddress(responseData.address)
                    await setStartPrice(responseData.startPrice)
                    await setStartDate(responseData.startDate)
                    await setEndDate(responseData.endDate)
                    await setDayPrice(responseData.dayPrice)
                    await setWeekPrice(responseData.weekPrice)
                    await setMonthPrice(responseData.monthPrice)
                    await setDelivery_type(responseData.delivery_type)


                } else {
                    Notification('error', response.data.data);
                }

                Loader('remove');
            }).catch(async (error) => {
                // درصئرت بروز هر خطایی این پیغام نمایش داده می شود
                if (error.response) {
                    if (error.response.status == 401) {
                        window.location.href = '/'
                    }
                    Loader('remove');
                }
            })
    }, []);

    return (
        <>
            <div className="row theAdDetailPage">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title={title} back='true' help="helpModuleCar"/>
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            <section className='form-2'>
                                                {photos[0] && (
                                                    <Carousel className='ltr' autoPlay={true} infiniteLoop={true} interval={8000} showArrows={false}
                                                        showIndicators={true}
                                                        showThumbs={false} showStatus={false} transitionTime={1000}>
                                                        {photos.map(item =>
                                                            <div>
                                                                <img src={item} alt='slider' title='slider' style={{ height: '200px' }} />
                                                            </div>
                                                        )}
                                                    </Carousel>
                                                )}
                                                {video && (
                                                    <>
                                                        <br /> <br />
                                                        <video id="player" style={{ width: '100%', height: ' 300px' }} playsinline controls data-poster={video}>
                                                            <source src={video} type="video/mp4" />
                                                            <source src={video} type="video/webm" />
                                                            <track kind="captions" label="English captions" src="/path/to/captions.vtt" srclang="en" default />
                                                        </video>
                                                    </>
                                                )}
                                                <br/>
                                                <h5>{title}</h5>
                                                <ul>
                                                    { phone != 'false' && (<li><b>تلفن فروشنده : </b> <span>{phone}</span></li>)}
                                                    { address != 'false' && (<li><b>آدرس فروشنده : </b> <span>{address}</span></li>)}
                                                    { brand && (<li><b>برند : </b> <span>{brand}</span></li>)}
                                                    { tip && (<li><b>تیپ : </b> <span>{tip}</span></li>)}
                                                    { startDate != 'false'  && (<li><b>تاریخ شروع : </b> <span>{startDate}</span></li>)}
                                                    { endDate != 'false'  && (<li><b>تاریخ پایان : </b> <span>{endDate}</span></li>)}

                                                    { dayPrice != 'false'  && (<li><b>قیمت روزانه : </b> <span>{dayPrice}</span></li>)}
                                                    { weekPrice != 'false'  && (<li><b>قیمت هفتگی : </b> <span>{weekPrice}</span></li>)}
                                                    { monthPrice != 'false'  && (<li><b>قیمت ماهانه : </b> <span>{monthPrice}</span></li>)}
                                                    { delivery_type != 'false'  && (<li><b>نحوه تحویل : </b> <span>{delivery_type}</span></li>)}

                                                    { model != 'false'  && (<li><b>مدل : </b> <span>{model}</span></li>)}
                                                    { fuel != 'false'  && (<li><b>سوخت : </b> <span>{fuel}</span></li>)}
                                                    { gearbox != 'false' && (<li><b>گیربکس : </b> <span>{gearbox}</span></li>)}
                                                    { color != 'false' && (<li><b>رنگ : </b> <span>{color}</span></li>)}
                                                    { work != 'false' && (<li><b>کارکرد : </b> <span>{work}</span></li>)}
                                                    { body_status != 'false'  && (<li><b>وضعیت بدنه : </b> <span>{body_status}</span></li>)}
                                                    { engine_status != 'false'  && (<li><b>وضعیت موتور : </b> <span>{engine_status}</span></li>)}
                                                    { gearbox_status != 'false'  && (<li><b>وضعیت گیربکس : </b> <span>{gearbox_status}</span></li>)}
                                                    { third_party_insurance != 'false'  && (<li><b>بیمه شخص ثالث : </b> <span>{third_party_insurance}</span></li>)}
                                                    { body_insurance != 'false'  && (<li><b>بیمه بدنه : </b> <span>{body_insurance}</span></li>)}

                                                    { price != 'false'  && (<li><b>قیمت : </b> <span>{price}</span></li>)}
                                                    { startPrice != 'false'  && (<li><b>شروع قیمت : </b> <span>{startPrice}</span></li>)}

                                                    { payment_method != 'false'  && (<li><b>نحوه پرداخت : </b> <span>{payment_method}</span></li>)}
                                                    { delivery_time != 'false'  && (<li><b>زمان تحویل : </b> <span>{delivery_time}</span></li>)}
                                                    { testingTime != 'false'  && (<li><b>زمان تست : </b> <span>{ testingTime != 0 && (testingTime)}</span></li>)}
                                                    { change != 'false'  && (<li><b>معاوضه : </b> <span>{ change == 1 && ('بله')}</span></li>)}
                                                </ul>

                                                { deductions_1  != 'false' && (
                                                    <>
                                                        <h5>اضافات : </h5>
                                                        <ul className='ul2'>
                                                            {
                                                                deductions_1.map((item, index) => {
                                                                    return (
                                                                        <li><span>{item.title}</span></li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </>
                                                )}

                                                { deductions_0 != 'false' && (
                                                    <>
                                                        <h5>کسورات : </h5>
                                                        <ul className='ul2'>
                                                            {
                                                                deductions_0.map((item, index) => {
                                                                    return (
                                                                        <li><span>{item.title}</span></li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </>
                                                )}
                                                { description && (<p>{description}</p>)}
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar />
                    </div>
                </div>
            </div>
        </>
    )
}
export default FormDetail;
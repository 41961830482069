import React, { Component, useState, useEffect, memo } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";
import $ from 'jquery';
import axios from 'axios';
import useScript from './../../hook/useScript';
import Notification from './../../components/notification';
import { useCookies } from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import Loader from '../../components/loader';
import Field from '../../components/field';

//افزودن آگهی
const ModuleCarAddMoney = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    //بررسی اجازه نمایش صفحه
    if (props.siteData.moduleMoneyAllowShow != 1)
        history.push("/");

    let pageTitle = 'ثبت درخواست ارزی جدید';

    { document.title = pageTitle }

    // ###############################
    // ###########       Set Data
    const [cookies, setCookie] = useCookies();
    const [monies, setMonies] = useState([]);
    const [deliveries, setDeliveries] = useState([]);

    const [moneyPrice, setMoneyPrice] = useState('');
    const [money, setMoney] = useState('');
    const [delivery, setDelivery] = useState('');
    const [amount, setAmount] = useState('');
    const [bankNumber, setBankNumber] = useState('');
    const [payAmount, setPayAmount] = useState('');

    // ###############################
    // ذخیره
    const saveForm = async () => {
        // نمایش لودر
        Loader('show');

        // گرفتن داده های ورودی
        let data = new FormData();
        data.append("money", money);
        data.append("delivery", delivery);
        data.append("amount", amount);
        data.append("bankNumber", bankNumber);


        // تنظیم مسیر درخواست
        const url = process.env.REACT_APP_URL + '/api/v1/moduleMoney/request/store';
        axios({
            method: 'post', url: url,
            data,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (res.data.status == 1) {
                    Notification('success', res.data.data);

                    // انتقال صفحه به صفحه لیست مقالات من
                    setTimeout(function () {
                        history.push("/moduleMoney");
                    }, 500);

                } else {
                    const resData = res.data.data;
                    if (Array.isArray(resData)) {
                        res.data.data.forEach(function (currentValue, index, arr) {
                            Notification('error', currentValue);
                        })
                    } else
                        Notification('error', resData);
                }
            }))
            .catch(error => {
                // درصورت بروز هر خطای این پیغام نمایش داده می شود
                if (error.response) {
                    Notification('error', "خطای پیش بینی نشده! لطفا دوباره امتحان کنید ");
                }
            })
        Loader('remove');
    }

    // ###############################
    // درخواست لیست پول ها
    useEffect(() => {
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/moduleMoney/money/all';
        axios({
            method: 'post', url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        }).then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    await setMonies(response.data.data)
                }
                Loader('remove');
            })
    }, []);

    // ###############################
    // درخواست لیست  نحوه پرداخت
    useEffect(() => {
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/moduleMoney/money/deliveries';
        axios({
            method: 'post', url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        }).then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    await setDeliveries(response.data.data)
                }
                Loader('remove');
            })
    }, []);

    // ###############################
    const handelMoney = (index) => {
        setMoney(monies[index]['id']);
        setMoneyPrice(monies[index]['price']);
        setPayAmount(monies[index]['price'] * amount)
    };


    const handelAmount = (amount) => {
        setAmount(amount);
        setPayAmount(moneyPrice * amount)
    };


    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title={pageTitle} back='true' help="helpModuleMoney" />
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            <section className="form-2">

                                                <div className="form-group">
                                                    <label htmlFor="amount" className="form-label">میزان درخواست</label>
                                                    <input type="number" className="form-control" id='amount' value={amount}
                                                        onChange={(value) => {
                                                            handelAmount(value.currentTarget.value);
                                                        }} />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="money" className="form-label">ارز مورد نظر</label>
                                                    <select className="form-control" id="money" defaultValue={String(money)}
                                                        onChange={(value) => {
                                                            handelMoney(value.currentTarget.value);
                                                        }}>
                                                        {
                                                            monies.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <option value={index} data-price={item.price} selected={money == item.id}>{item.title} :  {item.priceFormat} تومان</option>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="delivery" className="form-label">نحوه تحویل ارز</label>
                                                    <select className="form-control" id="delivery" defaultValue={String(delivery)}
                                                        onChange={(value) => {
                                                            setDelivery(value.currentTarget.value);
                                                        }}>
                                                        {
                                                            deliveries.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.id} selected={delivery == item.id}>{item.title}</option>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="bankNumber" className="form-label">شماره حساب بانک قبرس</label>
                                                    <input type="text" className="form-control" id='bankNumber' value={bankNumber}
                                                           onChange={(value) => {
                                                               setBankNumber(value.currentTarget.value);
                                                           }} />
                                                </div>

                                                <div className="form-group mb-5">
                                                    <label htmlFor="title" className="form-label">مبلغ قابل پرداخت : <b>{payAmount}</b> تومان</label>
                                                </div>


                                                <div className="">
                                                    <button className="btn btn-outline-info btn-1 hover2" onClick={saveForm}>ثبت درخواست</button>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModuleCarAddMoney;
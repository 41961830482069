import React, {Component, useState, useEffect, memo} from 'react';
import {
    Link,
    useHistory,
    useParams
} from "react-router-dom";


import $ from 'jquery';
import axios from 'axios';
import useScript from './../../hook/useScript';
import Notification from './../../components/notification';
import {useCookies} from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import Loader from '../../components/loader';


// جرئیات رویداد
const EventDetail = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    //بررسی اجازه نمایش صفحه
    if (props.siteData.eventAllowShow != 1)
        history.push("/");

    const [cookies, setCookie] = useCookies();
    const slug = useParams();

    // ###############################
    // ###########       Set Data
    const [title, setTitle] = useState('');
    const [theSlug, setTheSlug] = useState('');
    const [photo, setPhoto] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [description, setDescription] = useState('');

    //############################
    //  درخواست جزئیات
    useEffect(() => {
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/event/detail';
        axios({
            method: 'post',
            data: {'slug': slug},
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status == 1) {
                    await setTitle(response.data.data.title)
                    await setDescription(response.data.data.description)
                    await setPhoto(response.data.data.photo)
                    await setStart(response.data.data.start)
                    await setEnd(response.data.data.end)
                    await setTheSlug(response.data.data.slug)

                } else {
                    Notification('error', response.data.data);
                }
                Loader('remove');
            }).catch(async (error) => {
            if (error.response) {
                if (error.response.status == 401) {
                    window.location.href = '/'
                }
                Loader('remove');
            }

        })
    }, []);
    {document.title = title}

    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title={title} back='true'/>
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            <div className="card">
                                                <div className="p-2">
                                                    <h5 className="card-title">{title}</h5>
                                                    <p className=""><b>تاریخ شروع : </b>{start}</p>
                                                    <p className=""><b>تاریخ پایان : </b>{end}</p>
                                                    <img src={photo} alt={title} title={title} style={{ width:'100%' , height:'200px' }}/>
                                                    <br/> <br/>
                                                    <p>{description}</p>

                                                    <Link to={'/event/register/' + theSlug} className="btn btn-1 hover2 w-100">ثبت نام در رویداد</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <TaskBar/>

                    </div>
                </div>
            </div>

        </>
    )
}
export default EventDetail;




import React, {Component, useState, useEffect, memo} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import useScript from "../../hook/useScript";
import {useCookies} from "react-cookie";
import Header from "../../layouts/header";
import TaskBar from "../../layouts/taskBar";
import Loader from '../../components/loader';
import Notification from "../../components/notification";

// لیست خودرو ها
const ModuleCarRents = (props) => {
    // برای جابجایی بین صفحات
    const history = useHistory();

    //بررسی اجازه نمایش صفحه
    if (props.siteData.moduleCarAllowShow != 1)
        history.push("/");

    Loader('show');
    {document.title = 'اجاره خودرو'}

    const [cookies, setCookie] = useCookies();
    const [moduleCar, setModuleCar] = useState([])


    // ###############################
    // درخواست همه
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/moduleCar/car/all/3';
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setModuleCar(response.data.data);
                } else {
                    Notification('error', response.data.data);
                }

                Loader('remove');
            })
    }, []);

    const myAds = async () => {
        Loader('show');
        const url = process.env.REACT_APP_URL + '/api/v1/moduleCar/car/all/3';
        let data = new FormData();
        data.append("user", 1);

        axios({
            method: 'post',
            url: url,
            data,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setModuleCar(response.data.data);
                } else {
                    Notification('error', response.data.data);
                }

                Loader('remove');
            })
    }


    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-lg-4 mx-auto mt-md-4">
                    <div className="content-original mx-auto">
                        <div className="content-box p-0">
                            <Header siteData={props.siteData} title='اجاره خودرو' back='true' help="helpModuleCar"/>
                            <div className="visible-yscroll">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="list-style1 page-2 box-1">
                                            <div className="moduleCarItems all d-flex">
                                                <div className="col-4 item">
                                                    <div className="card">
                                                        <Link to='/moduleCar/rent/add' className="text-center">
                                                            <h5>ثبت آگهی</h5>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-4"></div>
                                                <div className="col-4 item">
                                                    <div className="card">
                                                        <h5 onClick={myAds}  className="text-center" style={{cursor:"pointer"}}>آگهی های من</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                moduleCar.map((item, index) => {
                                                    return (
                                                        <div className="col-12 item">
                                                            <div className="card">

                                                                <Link to={'/moduleCar/detail/' + item.id} className="text-center">

                                                                    <img src={ item.photo} className="card-img-top"  alt={item.title} title={item.title} />
                                                                    <span className="showDateSpan">{item.date}  </span>

                                                                    <div className="card-body">
                                                                        <h5>{item.title}</h5>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TaskBar/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModuleCarRents;
import React, { useRef, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import axios from 'axios'
import Notification from './../../components/notification';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loader from '../../components/loader';
import Field from '../../components/field';

// صفحه ورود کاربر
const Login = (props) => {
    { document.title = 'ورود به باشگاه' }

    //  برای جابجای بین صفحات
    const history = useHistory();
    const [cookies, setCookie] = useCookies();

    // بررسی اینکه قبلا احرازهویت صورت گرفته یا نه
    if (cookies.tokenForAccess)
        history.push("/");

    // set data
    const [slider, setSlider] = useState([]);
    const [faq, setPageFaq] = useState('');
    const [help, setPageHelp] = useState('');

    // ###############################
    // ########### m   ارسال درخواست به سرور و دریافت اسلایدر
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/slider';
        Loader('show');
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setSlider(response.data.data);
                }
                Loader('remove');
            })
            .catch(error => {
                // درصورت بروز هر خطای این پیغام نمایش داده می شود
                const resData = error.response.data.errors;
                if(typeof resData === 'object') {
                    for (const [key, value] of Object.entries(resData)) {
                        Notification('error', value);
                    }
                }else
                    Notification('error', 'خطای پیش بینی نشده . لطفا کمی بعد مجددا امتحان فرمائید');

                Loader('remove');
            })
    }, []);

    // ###############################
    // ###########p   درخواست راهنمای صفحه
    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/api/v1/pages';
        axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: 'Bearer ' + cookies.tokenForAccess,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(async (response) => {
                // بررسی وضعیت برگشت داده
                // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                if (response.data.status === 1) {
                    await setPageFaq(response.data.data["fuq"]);
                    await setPageHelp(response.data.data["help"]);
                }
            })
    }, []);


    // ###############################
    // ###########m   ست کردن تنظیمات سایت
    const [siteData, setSiteData] = useState({
        'logo': '',
        'siteName': '',
        'userAvatar': '',
        'mainBackground': '',
        'menuBackground': '',
        'appBackground': '',
        'loginBackgroundPhoto': '',
    });
    useEffect(() => {
        if (props.siteData) {
            setSiteData({
                'logo': props.siteData.logo,
                'siteName': props.siteData.siteName,
                'userAvatar': props.siteData.userAvatar,
                'mainBackground': props.siteData.mainBackground,
                'menuBackground': props.siteData.menuBackground,
                'appBackground': props.siteData.appBackground,
                'loginBackgroundPhoto': props.siteData.loginBackgroundPhoto,
            })
        }
    }, [props.siteData])

    // ###############################
    // ###############################
    // ###############################
    // ###########       Set Data
    const [mobile, setMobile] = useState('');
    const [national_code, setNationalCode] = useState('');
    const [password, setPassword] = useState('');
    const [verifyCode, setVerifyCode] = useState('');
    const [pageStatus, setPageStatus] = useState(0);
    const [userCookie, setUserCookie] = useCookies(['id', 'mobile', 'tokenForAccess', 'firstName', 'lastName']);

    // ###############################
    // ###############################
    // ###############################
    // ###########       Login
    const loginAxios = async () => {
        if (!mobile)
            Notification('error', "وارد کردن موبایل الزامیست");
        if (!password)
            Notification('error', "وارد کردن رمز عبور الزامیست");

        if (mobile && password) {
            let data = new FormData();
            data.append('mobile', mobile);
            data.append('password', password);
            Loader('show');
            axios.post(
                process.env.REACT_APP_URL + '/api/v1/user/authentication',
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then((res => {
                    // بررسی وضعیت برگشت داده
                    // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                    if (res.data.status == 1) {
                        // قرار دادن اطلاعات کاربر در کوکی مرورگر
                        let today = new Date(new Date);
                        today.setDate(today.getDate() + 7)
                        setUserCookie('id', res.data.data.id, { path: '/', expires: today })
                        setUserCookie('tokenForAccess', res.data.data.token, { path: '/', expires: today })
                        setUserCookie('firstName', res.data.data.firstName, { path: '/', expires: today })
                        setUserCookie('lastName', res.data.data.lastName, { path: '/', expires: today })
                        this.props.history.push("/");
                    } else {
                        Notification('error', res.data.data);
                    }
                    Loader('remove');
                }))
                .catch(error => {
                    if (error.response) {
                        Notification('error', "خطای پیش بینی نشده! لطفا دوباره امتحان کنید ");
                    }
                    Loader('remove');
                })
        }

    }


    // ###############################
    // فرم فراموشی رمز عبور
    const forgetPassAxios = async () => {
        if (mobile == '') {
            Notification('error', 'وارد کردن شماره موبایل الزامیست');
        } else {
            let data = new FormData();
            data.append("mobile", mobile);
            Loader('show');

            // ارسال درخواست به سرور
            axios.post(
                process.env.REACT_APP_URL + '/api/v1/user/forgetPass',
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then((res => {
                    // بررسی وضعیت برگشت داده
                    // وضعیت 1 یعنی درخواست با موفقیت رفته و برگشته
                    if (res.data.status == 1)
                        Notification('success', 'رمز جدید برای شما ارسال گردید');
                    else
                        Notification('error', res.data.data);

                    Loader('remove');
                }))
                .catch(error => {
                    //  بروز هرگئنه خطایی از سرور
                    if (error.response) {
                        Notification('error', "خطای پیش بینی نشده! لطفا دوباره امتحان کنید ");
                    }
                });
            Loader('remove');
        }
    }


    return (
        <>
            <main>
                <div className="hidden-sm-up ltr">
                    <Carousel autoPlay={true} infiniteLoop={true} interval={8000} showArrows={false} showIndicators={true}
                        showThumbs={false} showStatus={false} transitionTime={1000}>
                        {slider.map(item =>
                            <div>
                                <img src={item.photo} alt='slider' title='slider' />
                            </div>
                        )}
                    </Carousel>
                </div>

                <div className="container-fluid">
                    <div className="row pt-4 mt-5">
                        <div className="col-12 col-md-6">
                            <div className="form-1">
                                <img className="main-logo" src={siteData.logo} alt="logo" />
                                <p className="text-center description"> به {siteData.siteName} خوش آمدید.</p>
                                <section className="form">
                                    <div className="form-group required req2 ">
                                        <Field dir='rtl' placeholder='موبایل' className="form-control" type='number' id="mobile" change={(value) => {
                                            setMobile(value)
                                        }} value={mobile} format='###########' />
                                        <span className="focus-border"><i></i></span>
                                    </div>
                                    <div className="form-group required req2">
                                        <input className="effect-7" type="password" placeholder="رمز عبور " id='password'
                                            onChange={(value) => {
                                                setPassword(value.currentTarget.value)
                                            }} value={password} />
                                        <span className="focus-border"><i></i></span>
                                    </div>
                                    <ul>
                                        <li>
                                            <button className="btn btn-1 mr-auto" onClick={loginAxios}>ورود</button>
                                            <br />
                                        </li>
                                        <li className="mb-3  text-right">
                                            <p className="description-for-user text-right">فراموشی رمز عبور ؟
                                                <span data-toggle="modal" data-target="#forget-password" className="link1 btn btn-1" style={{ width: '70px', minWidth:'70px', display: 'initial' , marginRight: '5px'}}> فراموشی رمز  </span></p>
                                        </li>

                                        <li className="text-right">
                                            <p className="description-for-user text-right">هنوز ثبت نام نکرده اید ؟ 
                                            <Link to="/register" className="link1 btn btn-1" style={{ width: '70px', minWidth:'70px', display: 'initial' , marginRight: '5px'}}>ثبت نام</Link></p>
                                        </li>
                                        <li>
                                            <br />
                                            <p className="description-for-user text-right">
                                                در صورتی که در سامانه به مشکل برخوردید به صفحه سوالات متداول مراجعه نمایید.
                                            </p>
                                        </li>
                                        <li>
                                            <ul className="col-md-12 guide-faq">
                                                <li className="col-6 ">
                                                    <button className='btn btn-1 mx-auto' data-toggle="modal" data-target="#guidanceModal">راهنمای
                                                        اعضای جدید
                                                    </button>
                                                </li>
                                                <li className="col-6">
                                                    <button className='btn btn-1 mx-auto' data-toggle="modal" data-target="#faqModal">سوالات متداول
                                                    </button>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                    {/*مودال فراموشی رمز عبور*/}
                                    <div className="modal fade" id="forget-password" role="dialog">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <h2 className="modal-title">فراموشی رمز عبور</h2>
                                                <button type="button" className="close" data-dismiss="modal">
                                                    <span aria-hidden="true">×</span>
                                                    <span className="sr-only">Close</span>
                                                </button>
                                                <div className=" col-md-12 modal-body">

                                                    <div className="form-group forget-password required">
                                                        <label>
                                                            شماره تلفن همراه:
                                                        </label>
                                                        <input className="form-control effect-7 mobile" type="number" id="mobile" value={mobile}
                                                            onChange={(value) => {
                                                                setMobile(value.currentTarget.value)
                                                            }} />
                                                        <span className="focus-border"><i></i></span>
                                                    </div>

                                                </div>

                                                <div className="modal-footer">
                                                    <button type="button" onClick={forgetPassAxios} className="btn btn-1 ml-2 hover1"
                                                        data-dismiss="modal">دریافت رمز عبور جدید
                                                    </button>
                                                    <button type="button" id="changeBtn" className="btn btn-default" data-dismiss="modal">انصراف </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </section>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 hidden-sm-down ltr">
                            <Carousel autoPlay={true} infiniteLoop={true} interval={8000} showArrows={false} showIndicators={true}
                                showThumbs={false} showStatus={false} transitionTime={1000}>
                                {slider.map(item =>
                                    <div>
                                        <img src={item.photo} alt='slider' title='slider' />
                                    </div>
                                )}
                            </Carousel>
                        </div>

                    </div>
                </div>

                <footer className="hidden-sm-down" style={{ backgroundImage: `url(${siteData.loginBackgroundPhoto})`}}></footer>

                {/* مودال پرسش و پاسخ */}
                <div className="modal fade" id="faqModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                            <div className="modal-body">
                                <div dangerouslySetInnerHTML={{ __html: faq }} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-1 hover2" data-dismiss="modal">بستن</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* مودال راهنما */}
                <div className="modal fade" id="guidanceModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                            <div className="modal-body">
                                <div dangerouslySetInnerHTML={{ __html: help }} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-1 hover2" data-dismiss="modal">بستن</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default Login;
